import api from './API'

const URL = '/projects'

export default {
  list: function () {
    return api.newRequest('get', URL)
  },
  view: function (id) {
    return api.newRequest('get', `${URL}/${id}`)
  },
  create: function (item) {
    return api.newRequest('post', URL, item)
  },
  update: function (item) {
    return api.newRequest('patch', `${URL}/${item.id}`, item)
  },
  delete: function (id) {
    return api.newRequest('delete', `${URL}/${id}`)
  }
}
