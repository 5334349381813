<template>
  <v-container fluid>
    Logging out...
  </v-container>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
  },
  created () {
    this.$store.dispatch('auth/signout')
  }
}
</script>
