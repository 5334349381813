<template>
  <v-container id="welcomeScreen" class="fill-height bg" fluid :style="{ backgroundImage: `url('${backgroundURL}')` }">
    <div class="logo">
      <img :src="logoURL" alt="">
    </div>
    <v-row class="fade-in text-center" no-gutters align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-carousel class="carousel" hide-delimiter-background :show-arrows="false" cycle height="200">
          <v-carousel-item v-for="message in welcomeMessages" :key="message.title">
            <v-sheet
              color="transparent"
              height="100%"
              tile
            >
            <v-row no-gutters :style="`color: ${welcomeTextColour};`">
              <v-col cols="12">
                <div class="headline">{{message.title}}<br>
                <span class="body-1">{{message.text}}</span>
                </div>
              </v-col>
            </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="center" class="text-center fade-in">
      <v-col cols="5" sm="3">
        <v-btn color="primary" rounded block to="/login">Login</v-btn><br>
        <!-- <v-btn rounded outlined block dark to="/register">Register</v-btn> -->
      </v-col>
    </v-row>
    <v-footer
      padless
      fixed
      class="transparent"
    >
      <v-col class="text-center white--text" cols="12">
      &copy;{{ new Date().getFullYear() }} - Powered by FirstVue&trade; <strong>v.{{vueVersion}}</strong>
    </v-col>
    </v-footer>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    logoURL () {
      return `${this.assetServerURL}${this.welcomeLogoURL}`
    },
    backgroundURL () {
      return `${this.assetServerURL}${this.welcomeBackgroundURL}`
    },
    ...mapState('app', ['welcomeLogoURL', 'welcomeBackgroundURL', 'welcomeMessages', 'welcomeTextColour']),
    ...mapGetters('app', ['assetServerURL'])
  },
  data: () => ({
    vueVersion: process.env.VUE_APP_VERSION
  }),
  created () {
    console.log('WelcomeCustom.vue')
  },
  beforeCreate () {
    document.body.classList.add('body-noscroll')
  },
  beforeDestroy () {
    document.body.classList.remove('body-noscroll')
  },
  methods: {
  }
}
</script>

<style scoped>
.bg {
  /* background: url("/img/welcome-screen.png"); */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 180px;
  margin-top: -90px; /* Half the height */
  margin-left: -82px; /* Half the width */
  -webkit-animation: slide-bck-top 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) 2s both;
  animation: slide-bck-top 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) 2s both;
}

@-webkit-keyframes slide-bck-top {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
            top: 50%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
            top: 15%;
  }
}
@keyframes slide-bck-top {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
            top: 50%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
            top: 15%;
  }
}

.fade-in{
  -webkit-animation: 5s ease 0s normal forwards 1 fadein;
  animation: 5s ease 0s normal forwards 1 fadein;
}

@keyframes fadein{
  0% { opacity:0; }
  66% { opacity:0; }
  100% { opacity:1; }
}

@-webkit-keyframes fadein{
  0% { opacity:0; }
  66% { opacity:0; }
  100% { opacity:1; }
}

.carousel {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

</style>
