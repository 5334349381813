<template>
  <v-container fluid>
    <v-layout align-center justify-center v-if="error">
        <v-flex xs12 sm6 md4 lg3 xl2>
            <v-card class="mb-4">
                <v-card-text>
                <p class="mb-0 red--text"><b>{{error.title}}:</b> {{error.message}}</p>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
    <v-layout v-if="showForm">
      <v-flex xs12 sm6 offset-sm3 md4 offset-md4>
        <v-card>
          <v-container>
            <v-card-text>
              <div class="headline mb-4 text-center">Welcome to {{appName}}</div>
              <p><b>{{inviteeName}}</b> has invited you to join <b>{{organisation}}</b> organisation in <b>{{appName}}</b> App. Please set a new password in order to access the account.</p>
              <v-form id="registration-form" ref="form" v-model="valid" lazy-validation>
                <v-text-field
                v-model="password"
                :rules="[rules.password]"
                label="New Password"
                prepend-icon="mdi-lock"
                type="password"
              ></v-text-field>
              <v-text-field
                v-model="passwordConfirmation"
                :error-messages='passwordMatchError()'
                label="Confirm Password"
                prepend-icon="mdi-lock"
                type="password"
              ></v-text-field>
              <v-checkbox
                  v-model="agreed"
                  :rules="[rules.agree]"
                  required
                  hide-details
                  class="mb-5"
                >
                <template v-slot:label>
                  <div>
                  By creating a FirstVue&trade; account, I agree to the <a @click="showTerms">Terms and Conditions</a>
                  and <a @click="showPrivacyPolicy">Privacy Policy</a>
                  </div>
                </template>
                </v-checkbox>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="submit"
                  :disabled="loading"
                  :loading="loading"
                  form="registration-form"
                  >
                  submit
                </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="invitationComplete">
      <v-flex xs12 sm6 offset-sm3 md4 offset-md4>
        <v-card>
          <v-card-text>
            <p class="mb-0 green--text"><b>Password updated.</b> You can now login to <b>{{appName}}</b> app with your new password. </p>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn type="submit" color="primary" to="/login">Login</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <webpage-dialog
      :title="webpageTitle"
      :model="showWebpageDialog"
      :url="webpageURL"
      :fullscreen="webpageIsFullscreen"
      :didClose="didCloseWebpage">
    </webpage-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    valid: false,
    invitationComplete: false,
    isValid: false,
    agreed: false,
    isHashValid: false,
    resetComplete: false,
    password: '',
    passwordConfirmation: '',
    hash: '',
    rules: {
      agree: v => !!v || 'You must agree to continue!',
      password: v => !!v || 'Password is required'
    },
    inviteeName: '',
    email: '',
    organisation: '',
    webpageURL: '',
    webpageTitle: '',
    webpageIsFullscreen: true,
    showWebpageDialog: false
  }),
  computed: {
    showForm () {
      return this.isHashValid && !this.invitationComplete
    },
    comparePasswords () {
      return this.password !== this.confirmPassword ? 'Passwords do not match' : true
    },
    ...mapState('app', { appName: 'name' }),
    ...mapState('common', ['error', 'loading'])
  },
  created () {
    this.hash = this.$route.params.hash
    this.$store.dispatch('auth/validateInvitationHash', { hash: this.hash }).then(resp => {
      this.isHashValid = true
      this.inviteeName = resp.data.invitee
      this.organisation = resp.data.organisation
      this.email = resp.data.email
    }).catch((error) => {
      console.log(error)
      this.isHashValid = false
    })
  },
  methods: {
    passwordMatchError () {
      return (this.password === this.passwordConfirmation) ? '' : 'Passwords don\'t match'
    },
    submit () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('auth/acceptInvitation', {
          hash: this.hash,
          password: this.password
        }).then(() => {
          this.invitationComplete = true
        })
      }
    },
    didCloseWebpage () {
      this.showWebpageDialog = false
    },
    showTerms () {
      this.webpageURL = 'https://firstvue.io/terms-and-conditions/'
      this.showWebpageDialog = true
    },
    showPrivacyPolicy () {
      this.webpageURL = 'https://firstvue.io/privacy-policy/'
      this.showWebpageDialog = true
    }
  }
}
</script>
