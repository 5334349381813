<template>
  <v-dialog v-model="dialogVisible" max-width="560" persistent>
    <v-card>
      <v-card-title class="headline">{{title}}</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <span class="subtitle-1">{{message}}</span>
            </v-col>
            <v-col cols="12" v-for="question in questions" :key="question.id">
              <v-autocomplete v-if="question.type === 'multiselect' || question.type === 'select' "
                v-model="answers[question.id]"
                :items="question.options"
                :label="`${question.title}*`"
                item-value="name"
                item-text="name"
                :error-messages="question.id in errors ? errors[question.id] : ''"
                :multiple="question.type === 'multiselect'"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="text-center">
        <v-btn block type="submit" class="primary" :disabled="loading" :loading="loading" @click="submit">Continue</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    model: Boolean,
    title: {
      type: String,
      default: 'Title'
    },
    account: Object,
    questions: Array,
    message: String
  },
  data: () => ({
    answers: {},
    errors: {},
    loading: false,
    dialogVisible: false
  }),
  watch: {
    model (val) {
      this.dialogVisible = val
    }
  },
  methods: {
    cancel () {
      this.$props.didCancel()
    },
    submit () {
      const that = this
      this.errors = {}
      this.questions.forEach(question => {
        if (!this.answers[question.id]) {
          this.errors[question.id] = 'Please select an option'
        }
      })
      if (Object.keys(this.errors).length > 0) {
        return
      }
      this.loading = true

      const answersPayload = []
      this.questions.forEach(question => {
        answersPayload.push({
          id: question.id,
          name: question.name,
          title: question.title,
          answers: this.answers[question.id]
        })
      })

      this.$store.dispatch('accounts/update', {
        id: this.account.id,
        introAnswers: answersPayload
      }).then(resp => {
        that.loading = false
        // Hide this dialog component by setting introQuestions to null
        that.$store.dispatch('auth/clearIntroQuestions')
      })
    },
    hide () {
      this.loading = false
    }
  }
}
</script>
