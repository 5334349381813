<template>
  <v-row>
    <v-col cols="12">
      <v-chart
        :options="userChartData"
        ref="bar"
        theme="ovilia-green"
        autoresize
      />
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="usersHeaders"
        :items="users"
        class="elevation-1"
        sort-by="lastSession"
        :sort-desc="[true]"
        :search="search"
        :loading="loading"
      >
      <template v-slot:top>
        <v-toolbar flat color="white" extended>
          <v-toolbar-title>User Accounts</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <b>Total: </b> {{userCount}}
            <template v-slot:extension>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </template>
          </v-toolbar>
        </template>
        <template v-slot:[`item.firstName`]="props">
          <router-link v-if="props.item.identityId" :to="`/insights/users/accounts/${props.item.identityId}`">{{props.item.firstName}} {{props.item.lastName}}</router-link>
          <template v-else>
            {{props.item.firstName}} {{props.item.lastName}}<br>
            <v-chip
            class="ma-2"
            color="warning"
            outlined
            pill
          >
            <v-icon left>mdi-alert-circle-outline</v-icon>
            Data not Available
          </v-chip>
          </template>
        </template>
        <template v-slot:[`item.lastSession`]="props">
          <div>
            <b>Last Session</b><br/>
            <span>{{ props.item.lastSession | formatDate }}</span>
          </div>
        </template>
        <template v-slot:[`item.sessionCount`]="props">
          <div>
            <span>{{ props.item.sessionCount }}</span>
          </div>
        </template>
        <template v-slot:no-data>
          There are no Accounts for this app yet.
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    search: '',
    userChartData: {
      color: ['#6a81ff'],
      legend: {},
      tooltip: { trigger: 'axis' },
      title: { text: 'Users', right: 'center' },
      dataset: {
        // Provide data.
        source: []
      },
      // Declare X axis, which is a category axis, mapping
      // to the first column by default.
      xAxis: { type: 'category' },
      // Declare Y axis, which is a value axis.
      yAxis: {},
      // Declare several series, each of them mapped to a
      // column of the dataset by default.
      series: [{ type: 'line' }],
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          }
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 70,
          end: 100
        },
        {
          start: 70,
          end: 100,
          handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }
      ]
    },
    usersHeaders: [
      { text: 'Name', align: 'left', sortable: true, value: 'firstName' },
      { text: 'Email', align: 'left', sortable: true, value: 'email' },
      { text: 'Session Count', align: 'left', sortable: true, value: 'sessionCount' },
      { text: 'Last Session', value: 'lastSession', sortable: true }
    ]
  }),
  computed: {
    ...mapState('analyticsReports', ['usersReport', 'users', 'userCount', 'sessionsByDate', 'sessionsByUser']),
    ...mapState('common', ['error', 'loading'])
  },
  created () {
    this.userChartData.dataset.source = this.usersReport
    this.userChartData.title.text = `Users (${this.userCount})`
  }
}
</script>
