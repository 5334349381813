<template>
  <v-dialog v-model="dialogVisible" max-width="400">
    <v-card>
      <v-container>
        <v-row align="center" justify="center" class="text-center pa-4">
          <div class="headline text-center mb-2">{{title}}</div>
          {{ message }}
          <div class="mt-4">
            <v-btn color="primary" rounded @click.native="didDismiss">Ok</v-btn>
          </div>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['model', 'didDismiss', 'title', 'message'],
  computed: {
  },
  data: () => ({
    dialogVisible: false
  }),
  watch: {
    model (val) {
      this.dialogVisible = val
    }
  },
  methods: {
    submit () {
      this.$props.didDismiss()
    }
  }
}
</script>
