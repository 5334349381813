<template>
  <v-container class="mt-0">
    <v-row v-if="error">
      <v-col cols="12" md="6" offset-md="6">
        <app-alert @dismissed="onDismissed" :title="error.title" :text="error.message"></app-alert>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12" class="text-center">
        <v-progress-circular indeterminate class="primary--text" :width="7" :size="70"></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="6" offset-md="3">
        <v-card>
          <v-card-title>
            <div>
              <div class="title">{{ account.firstName }} {{ account.lastName }}</div>
            </div>
            <template v-if="userIsMe">
              <v-spacer></v-spacer>
              <app-edit-account-details-dialog :account="account"></app-edit-account-details-dialog>
              <app-edit-account-extra-menu :account="account"></app-edit-account-extra-menu>
            </template>
          </v-card-title>
          <v-card-text>
            <span class="grey--text"> {{ account.email }} </span>
            <div>Role: {{account.role}}</div>
            <div v-if="account.lastSession != 'None'">Last login {{account.lastSession | formatDate}}</div>
            <div v-else>Last login: never</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    account () {
      return this.$store.getters['accounts/loadedAccount']
    },
    userIsMe () {
      return this.account.id === this.user.id
    },
    ...mapState('common', ['error', 'loading']),
    ...mapState('auth', ['user'])
  },
  methods: {
    onDismissed () {
      this.$store.dispatch('common/clearError')
    }
  },
  created () {
    this.$store.dispatch('accounts/view', 'me')
    this.$store.dispatch('analytics/logScreen', { name: 'user-account' })
  }
}
</script>
