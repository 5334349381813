const state = {
  isInPreviewMode: false,
  loading: false,
  error: null,
  globalError: null,
  globalNotification: null
}

const getters = {
  isInPreviewMode (state) {
    return state.isInPreviewMode
  },
  hasGlobalError (state) {
    return state.globalError != null
  },
  globalErrorTitle (state) {
    return state.globalError ? state.globalError.title : 'Unknown Error'
  },
  globalErrorMessage (state) {
    let message = 'Something\'s not right. Please try again later.'
    if (state.globalError !== null) {
      message = state.globalError.code === 0 ? 'Please check that you are connected to the internet and try again later.' : state.globalError.message
    }
    return message
  },
  globalNotification (state) {
    return state.globalNotification
  },
  isOnMobile (state) {
    return window.webkit && window.webkit.messageHandlers.takePhoto
  }
}

const actions = {
  clearError ({ commit }) {
    commit('clearError')
  },
  clearNotification ({ commit }) {
    commit('clearNotification')
  },
  showPreview ({ commit }, payload) {
    commit('setShowPreview', payload)
  }
}

const mutations = {
  setShowPreview (state, payload) {
    state.isInPreviewMode = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    console.log('setting error : ' + payload)
    if (payload.code === 0) {
      state.globalError = payload
    } else {
      state.error = payload
    }
  },
  clearError (state) {
    state.error = null
    state.globalError = null
  },
  setNotification (state, payload) {
    state.globalNotification = payload
  },
  clearNotification (state) {
    state.globalNotification = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
