<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      v-if="!navHidden"
    >
    <v-list>
      <template v-for="(item, i) in menuItems">
        <v-row v-if="item.heading" :key="i" align="center">
          <v-col cols="6">
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn small text>edit</v-btn>
          </v-col>
        </v-row>
        <v-divider v-else-if="item.divider" :key="i" dark class="my-4"></v-divider>
        <v-list-item v-else-if="item.action === 'settings'" :key='item.title' @click="showSettingsDialog = true">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey--text">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else :key='item.title' :to='item.link'>
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey--text">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider dark class="my-4"></v-divider>
      <v-list-item key="version">
          <v-list-item-content>
            <v-list-item-title class="grey--text">
              {{name}}{{envLabel}}<br>
              Version: {{buildVersion}}<br>
              API: {{serverVersion}}<br>
              FVA: {{ vueVersion }}<br>
            </v-list-item-title>
          </v-list-item-content>
      </v-list-item>
    </v-list>
    </v-navigation-drawer>
    <v-app-bar app clipped-left dense color="white" elevation="1">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!navHidden"></v-app-bar-nav-icon>
      <v-app-bar-nav-icon v-else>
        <template v-slot:default>
          <v-btn icon @click="didDismissView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/" tag='span' style='cursor: pointer' v-if="!navHidden">
          <img :src="logoURL" alt="" height="30">
        </router-link>
        <div v-else>
          {{navTitle}}
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <img :src="logoURL" alt="" height="30" v-if="navHidden"> -->

      <!-- <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="onLogout">
            <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <template v-slot:extension v-if="isInsightsView">
        <insights-tabbar></insights-tabbar>
      </template>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-bottom-navigation app :value="tabBarSelection" color="deep-blue accent-4" v-if="!navHidden">
      <v-btn value="recent" to="/">
        <span>Projects</span>
        <v-icon>mdi-home-group</v-icon>
      </v-btn>
      <!-- <v-btn value="insights" to="/insights/" v-if="this.isAccountAdmin">
        <span>Insights</span>
        <v-icon>mdi-chart-areaspline</v-icon>
      </v-btn> -->
      <!-- <v-btn value="favorites" to="/favourites">
        <span>Favorites</span>
        <v-icon>mdi-heart</v-icon>
      </v-btn> -->

      <!-- <v-btn value="about" :to="`/about/${organisationId}`">
        <span>About</span>
        <v-icon>mdi-information-variant</v-icon>
      </v-btn>

      <v-btn value="contact" :to="`/contact/${organisationId}`">
        <span>Contact Us</span>
        <v-icon>mdi-message-text</v-icon>
      </v-btn> -->
    </v-bottom-navigation>
    <!-- <settings-dialog :model="showSettingsDialog" :didCancel="didCancelSettings" :didSubmit="didSubmitSettings"></settings-dialog> -->
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
  },
  computed: {
    isInsightsView () {
      return this.$route.path.startsWith('/insights/')
    },
    logoURL () {
      return `${this.assetServerURL}${this.appLogoURL}`
    },
    envLabel () {
      if (this.env === 'prod') return ''
      return ` [${this.env}]`
    },
    menuItems () {
      const items = [
        { icon: 'mdi-home-group', title: 'Projects', link: '/' },
        // { icon: 'mdi-account', title: 'About Us', link: `/about/${this.organisationId}` },
        { divider: true },
        // { icon: 'mdi-settings', title: 'Settings', action: 'settings' },
        { icon: 'mdi-account', title: 'Account', link: '/account/' },
        { divider: true }
      ]
      if (this.isAccountAdmin) {
        // items.push({ icon: 'mdi-chart-areaspline', title: 'Insights', link: '/insights/' })
        // items.push({ divider: true })
        items.push({ icon: 'mdi-account-multiple', title: 'Organisation', link: '/organisations/' })
      }
      items.push({ icon: 'mdi-logout', title: 'Sign out', link: '/signout' })
      return items
    },
    ...mapState('app', ['name', 'appLogoURL', 'organisationId', 'serverVersion']),
    ...mapGetters('app', ['env', 'assetServerURL', 'buildVersion']),
    ...mapGetters('auth', ['isAccountAdmin'])
  },
  data: () => ({
    vueVersion: process.env.VUE_APP_VERSION,
    drawer: null,
    tabBarSelection: 0,
    dialogSettings: false,
    showSettingsDialog: false,
    navHidden: false,
    navTitle: ''
  }),
  created () {
    // These queries will be passed on from unity when loading a page without all navigation elements
    this.navTitle = this.$route.query.title
    this.navHidden = this.$route.query.navHidden
  },
  methods: {
    goHome () {
      this.$router.push('/')
    },
    didCancelSettings () {
      this.showSettingsDialog = false
    },
    didSubmitSettings () {
      this.showSettingsDialog = false
    },
    didDismissView () {
      window.location.href = 'uniwebview://dismiss-webview'
    }
  }
}
</script>
