<template>
  <v-container fluid>
    <v-container style="height: 400px;" v-if="isLoading">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="subtitle-1 text-center"
          cols="12"
        >
          Loading Events...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <v-row v-else>
      <v-col cols="12">
        <v-breadcrumbs :items="breadcrumbs" large divider=">"></v-breadcrumbs>
      </v-col>
      <v-col cols="12">
        <v-chart
          :options="identitySessionsChart"
          ref="bar"
          theme="ovilia-green"
          autoresize
        />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="sessionsHeaders"
          :items="identitySessions"
          class="elevation-1"
          show-expand
          :loading="loading"
          sort-by="dateEnd"
          :sort-desc="[true]"
        >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>User Sessions &amp; Events</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
            </v-toolbar>
          </template>
          <!-- <template v-slot:item="props">
            <tr v-if="!isMobile">
              <td class="text-xs-right"></td>
              <td class="text-xs-right">{{ props.item.id.substring(0, 8) }}...</td>
              <td class="text-xs-right">{{ props.item.dateCreated | formatDate}}</td>
              <td class="text-xs-right">{{ props.item.dateEnd | formatDate}}</td>
              <td class="text-xs-right">{{ props.item.duration | formatDuration}}</td>
              <td class="text-xs-right">{{ props.item.totalEvents }}</td>
            </tr>
            <tr v-else>
              <td>
                <ul class="flex-content">
                  <li class="flex-item" data-label="Name">{{ props.item.name }}</li>
                  <li class="flex-item" data-label="Calories">{{ props.item.calories }}</li>
                  <li class="flex-item" data-label="Fat (g)">{{ props.item.fat }}</li>
                  <li class="flex-item" data-label="Carbs (g)">{{ props.item.carbs }}</li>
                  <li class="flex-item" data-label="Protein (g)">{{ props.item.protein }}</li>
                  <li class="flex-item" data-label="Iron (%)">{{ props.item.iron }}</li>
                </ul>
              </td>
            </tr>
          </template> -->
          <!-- <template #item="{ item, index, isMobile, headers}">
            <tr v-if="isMobile">
              <td>{{headers[index].text}} {{isMobile}} foo {{item}} {{index}}</td>
            </tr>
            <tr v-else>
              <td class="text-start"><button type="button" class="v-icon notranslate v-data-table__expand-icon v-icon--link mdi mdi-chevron-down theme--light v-data-table__expand-icon--active" @click="item.expand(!item.isExpanded)"></button></td>
              <td>{{ item.id.substring(0, 8) }}...</td>
            </tr>
          </template> -->
          <template v-slot:[`item.id`]="props">
            <div v-if="props.item.isMobile">is mobile</div>
            <div v-else>{{ props.item.id.substring(0, 8) }}...</div>
          </template>
          <template v-slot:[`item.dateCreated`]="props">
            <div>{{ props.item.dateCreated | formatDate }}</div>
          </template>
          <template v-slot:[`item.dateEnd`]="props">
            <div>{{ props.item.dateEnd | formatDate }}</div>
          </template>
          <template v-slot:[`item.duration`]="props">
            <div>{{ props.item.duration | formatDuration }}</div>
          </template>
          <template #no-data>
            There are no Sessions for this user yet.
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <table width="100%">
                <thead>
                  <th class="text-left">Event name</th>
                  <th>Info</th>
                  <th class="text-right">Date &amp; Time</th>
                </thead>
                <tr v-for="(event, i) in item.events"
                  :key="i">
                  <td>
                    <v-chip v-if="event.type === 'screen'" outlined color="green" small>screen</v-chip>
                    <v-chip v-if="event.type === 'action'" outlined color="indigo" small>action</v-chip>
                    <!-- {{event.name}} -->
                    {{getEventName(event)}}
                    <!-- {{event.data}} -->
                  </td>
                  <td v-html="getEventInfo(event)"></td>
                  <td class="text-right caption">{{event.dateCreated | formatDate}}</td>
                </tr>
              </table>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    isLoading: true,
    isMobile: false,
    breadcrumbs: [
      {
        text: 'Insights',
        disabled: false,
        exact: true,
        to: '/insights/'
      },
      {
        text: 'Users',
        disabled: false,
        exact: true,
        to: '/insights/users/accounts'
      }
    ],
    tab: null,
    userCount: 0,
    identitySessionsChart: {
      color: ['#6a81ff'],
      legend: {},
      tooltip: { trigger: 'axis' },
      title: { text: 'Time Spent in App', right: 'center' },
      dataset: {
        // Provide data.
        source: []
      },
      // Declare X axis, which is a category axis, mapping
      // to the first column by default.
      xAxis: { type: 'category' },
      // Declare Y axis, which is a value axis.
      yAxis: {},
      // Declare several series, each of them mapped to a
      // column of the dataset by default.
      series: [{ type: 'bar' }],
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          }
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 70,
          end: 100
        },
        {
          start: 70,
          end: 100,
          handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }
      ]
    },
    sessionsHeaders: [
      { text: 'Id', align: 'left', sortable: false, value: 'id' },
      { text: 'Start', align: 'left', sortable: true, value: 'dateCreated' },
      { text: 'End', align: 'left', sortable: true, value: 'dateEnd' },
      { text: 'Duration', align: 'left', sortable: true, value: 'duration' },
      { text: 'Events', align: 'left', sortable: true, value: 'totalEvents' }
    ]
  }),
  watch: {
    loadedAccount (value) {
      this.breadcrumbs.push(
        {
          text: `${value.firstName} ${value.lastName}`,
          disabled: true
        }
      )
    },
    identitySessionsChartData (value) {
      this.identitySessionsChart.dataset.source = value
    }
  },
  computed: {
    ...mapState('analyticsReports', ['identitySessions', 'identitySessionsChartData', 'userId']),
    ...mapState('common', ['error', 'loading']),
    ...mapState('accounts', ['loadedAccount'])
  },
  mounted () {
    const that = this
    this.isLoading = true

    // const bar = this.$refs.bar
    // bar.showLoading({
    //   text: 'Loading…',
    //   color: '#4ea397',
    //   maskColor: 'rgba(255, 255, 255, 0.4)'
    // })
    const identityId = this.$route.params.id
    this.$store.dispatch('analyticsReports/getIdentityReport', identityId)
      .then(resp => {
        // bar.hideLoading()
        that.isLoading = false
      })
      .catch(error => {
        console.log(error)
        that.isLoading = false
      })
    this.$store.dispatch('accounts/view', this.userId)
  },
  created () {
  },
  methods: {
    getEventInfo (event) {
      if (event.name === 'select-project' || event.name === 'fv-scene-welcome') {
        return event.data.projectName
      } else if (event.name === 'fv-scene-building-info') {
        return event.data.locationName
      } else if (event.name === 'fv-scene-floorplate') {
        return event.data.floorName
      } else if (event.name === 'fv-scene-floorplan') {
        return event.data.lotName
      } else if (event.name === 'fv-scene-viewline') {
        return event.data.viewlineId
      } else if (event.name === 'select-webpage') {
        return `Project: <a href="${event.data.url}" target="_blank">${event.data.projectName}</a>`
      }
      return ''
    },
    getEventName (event) {
      if (event.name === 'home') {
        return 'App Homepage'
      } else if (event.name === 'select-project') {
        return 'Selected Project'
      } else if (event.name === 'fv-scene-welcome') {
        return 'Welcome'
      } else if (event.name === 'fv-scene-building-info') {
        return 'Location Info'
      } else if (event.name === 'fv-scene-floorplate') {
        return 'Selected Floor'
      } else if (event.name === 'fv-scene-floorplan') {
        return 'View Floorplan'
      } else if (event.name === 'fv-scene-viewline') {
        return 'Blacony View'
      } else if (event.name === 'select-webpage') {
        return 'Visited URL'
      } else if (event.name === 'organisation-about') {
        return 'About Us Page'
      } else if (event.name === 'organisation-contact') {
        return 'Contact Us Page'
      }
      return ''
    }
  }
}
</script>
