<template>
  <v-tabs
    background-color="indigo"
    dark
    v-model="tab"
    centered>
      <v-tab
      v-for="item in tabs"
      :key="item.name"
      :to="item.url"
      exact
      >
      {{item.title}}
      </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data: () => ({
    tab: null,
    tabs: [
      { id: '0', title: 'Summary', url: '/insights/' },
      { id: '1', title: 'Users', url: '/insights/users/accounts/' },
      { id: '2', title: 'Sessions', url: '/insights/sessions/' }
    ]
  })
}
</script>
