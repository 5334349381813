import accountAPI from '@/api/AccountAPI'

const state = {
  items: [],
  loadedAccount: {}
}

const getters = {
  items (state) {
    return state.items.sort((itemA, itemB) => {
      return itemA.date > itemB.date
    })
  },
  loadedAccount (state) {
    // Account is an object that looks like the below:
    // {account: {id, firstName, lastName, email, role, lastSession}, organisation:{id, name}}
    return state.loadedAccount
  }
}

const actions = {
  list ({ commit }) {
    commit('common/setLoading', true, { root: true })
    console.log('loading accounts')
    accountAPI.list()
      .then(resp => {
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        commit('setItems', resp.data.accounts)
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  view ({ commit }, id) {
    commit('common/setLoading', true, { root: true })
    accountAPI.view(id)
      .then(resp => {
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        commit('setLoadedAccount', resp.data.account)
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  create ({ commit, getters }, payload) {
    commit('common/setLoading', true, { root: true })
    return new Promise((resolve, reject) => {
      const account = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        role: payload.role,
        password: payload.password
      }
      accountAPI.create(account)
        .then(resp => {
          console.log('store/account :: created Account : resp=')
          commit('common/setLoading', false, { root: true })
          commit('common/clearError', null, { root: true })
          commit('addItem', resp.data)
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  },
  update ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    const updateObj = { id: payload.id }
    if (payload.firstName) {
      updateObj.firstName = payload.firstName
    }
    if (payload.lastName) {
      updateObj.lastName = payload.lastName
    }
    if (payload.email) {
      updateObj.email = payload.email
    }
    if (payload.role) {
      updateObj.role = payload.role
    }
    if (payload.password) {
      updateObj.password = payload.password
    }
    if (payload.introAnswers) {
      updateObj.introAnswers = payload.introAnswers
    }

    if (Object.prototype.hasOwnProperty.call(payload, 'isActive')) {
      updateObj.isActive = payload.isActive
    }
    return new Promise((resolve, reject) => {
      accountAPI.update(updateObj)
        .then(resp => {
          console.log('store/account :: updateAccount')
          commit('common/setLoading', false, { root: true })
          commit('common/clearError', null, { root: true })
          commit('setLoadedAccount', resp.data.account)
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  },
  async delete ({ commit }, accountId) {
    try {
      await accountAPI.delete(accountId)
      commit('common/setLoading', false, { root: true })
      commit('common/clearError', null, { root: true })
      commit('delete', accountId)
    } catch (error) {
      console.log(error)
      commit('common/setLoading', false, { root: true })
      commit('common/setError', error, { root: true })
      throw error
    }
  }
}
const mutations = {
  setItems (state, payload) {
    state.items = payload
  },
  setLoadedAccount (state, payload) {
    state.loadedAccount = payload
  },
  addItem (state, payload) {
    state.items.push(payload)
  },
  setItem (state, payload) {
    const item = state.items.find(item => {
      return item.id === payload.id
    })
    item.firstName = payload.firstName
    item.lastName = payload.lastName
    item.email = payload.email
    item.role = payload.role
  },
  delete (state, payload) {
    state.items = state.items.filter(function (item) { return item.id !== payload })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
