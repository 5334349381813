import api from './API'

const LOGIN_URL = '/auth/login'
const SIGNUP_URL = '/auth/register'
const NEW_PASSWORD_URL = '/auth/password/new'
const RESET_PASSWORD_URL = '/auth/password/reset'
const VALIDATE_RESET_PASSWORD_URL = '/auth/password/validate'
const CONFIRM_EMAIL_URL = '/auth/email/confirm'
const VALIDATE_INVITATION_URL = '/auth/invitation/validate'
const ACCEPT_INVITATION_URL = '/auth/invitation/accept'
// const LOGOUT_URL = '/logout'
// const CURRENT_USER_URL = '/user/'

export default {
  signup: function (firstName, lastName, email, password, organisation, phone, agreed) {
    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      organisation: organisation,
      phone: phone,
      agreed: agreed
    }
    return api.newRequest('post', SIGNUP_URL, payload).then(resp => {
      this.setAuthToken(resp.data.token, resp.data.user.role)
      return resp
    })
  },
  login: function (email, password, redirect) {
    const payload = { login: email, password: password }
    return api.newRequest('post', LOGIN_URL, payload).then(resp => {
      this.setAuthToken(resp.data.token, resp.data.user.role)
      return resp
    })
  },
  logout () {
    console.log('auth.js :: logout')
    this.setAuthToken(null, null)
  },
  resetPassword: function (email) {
    const payload = { email: email }
    return api.newRequest('post', RESET_PASSWORD_URL, payload).then(resp => {
      return resp
    })
  },
  validateResetPasswordHash: function (hash) {
    const payload = { hash: hash }
    return api.newRequest('post', VALIDATE_RESET_PASSWORD_URL, payload).then(resp => {
      return resp
    })
  },
  validateInvitationHash: function (hash) {
    const payload = { hash: hash }
    return api.newRequest('post', VALIDATE_INVITATION_URL, payload).then(resp => {
      return resp
    })
  },
  setNewPassword: function (hash, password) {
    const payload = { hash: hash, password: password }
    return api.newRequest('post', NEW_PASSWORD_URL, payload).then(resp => {
      return resp
    })
  },
  acceptInvitation: function (hash, password) {
    const payload = { hash: hash, password: password }
    return api.newRequest('post', ACCEPT_INVITATION_URL, payload).then(resp => {
      return resp
    })
  },
  confirmEmail: function (hash) {
    const payload = { hash: hash }
    return api.newRequest('post', CONFIRM_EMAIL_URL, payload).then(resp => {
      return resp
    })
  },
  setAuthToken (token, role) {
    console.log('auth.js :: setAuthToken : token=' + token + ', role=' + role)
    window.localStorage.removeItem('jwt')
    console.log('foobar removed jwt')
    console.log(window.localStorage.getItem('jwt'))
    window.localStorage.removeItem('role')
    if (token) {
      window.localStorage.setItem('jwt', token)
      console.log('foobar set jwt')
      console.log(window.localStorage.getItem('jwt'))
      window.localStorage.setItem('role', role)
    }
  }
}
