<template>
  <v-container fluid>
    <v-row>
      <!-- <v-col cols="12">
        <v-chart :options="polar"/>
      </v-col> -->
      <v-col cols="12">
        <v-chart
          :options="sessionsChartData"
          autoresize
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    sessionsChartData: {
      color: ['#61a0a8', '#2f4554', '#d48265', '#91c7ae', '#c23531', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],
      legend: {},
      tooltip: { trigger: 'axis' },
      title: { text: 'Sessions', right: 'center' },
      dataset: {
        // Provide data.
        source: []
      },
      // Declare X axis, which is a category axis, mapping
      // to the first column by default.
      xAxis: { type: 'category' },
      // Declare Y axis, which is a value axis.
      yAxis: {},
      // Declare several series, each of them mapped to a
      // column of the dataset by default.
      series: [{ type: 'line' }]
    }
  }),
  computed: {
    ...mapState('analyticsReports', ['usersReport', 'users', 'sessionsByDate', 'sessionsByUser']),
    ...mapState('common', ['error', 'loading'])
  },
  mounted () {
    this.sessionsChartData.dataset.source = this.sessionsByDate
  },
  created () {
  },
  methods: {
  }
}
</script>
