<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn text fab v-on="on" small>
            <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="deleteConfirmation = true">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="deleteConfirmation" max-width="320">
      <v-card>
        <v-card-title class="headline">Delete this Notification?</v-card-title>
        <v-card-text>This will unschedule this notifiation and remove it permanently. Are you sure you would like to continue?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="deleteConfirmation = false">Cancel</v-btn>
          <v-btn color="red darken-1" :loading="isDeleting" text @click.native="deleteNotification">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['notificationId'],
  data () {
    return {
      isDeleting: false,
      successTitle: '',
      successMessage: '',
      showSuccessDialog: false,
      editPassword: false,
      deleteConfirmation: false,
      deactivateConfirmation: false
    }
  },
  computed: {
  },
  methods: {
    deleteNotification () {
      const that = this
      this.isDeleting = true
      this.$store.dispatch('notifications/delete', this.notificationId)
        .then(resp => {
          that.isDeleting = false
          that.deleteConfirmation = false
        })
    }
  }
}
</script>
