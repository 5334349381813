<template>
<v-dialog v-model="showDialog" persistent max-width="500">
  <template v-slot:activator="{ on }">
    <v-btn text color="primary" dark class="mb-2" v-on="on"><v-icon dark class="mr-2">mdi-account-plus</v-icon>Add Account</v-btn>
  </template>
  <v-card>
    <v-card-text>
      <v-form id="newAccountForm" ref="newAccountForm" v-model="valid" lazy-validation @submit.prevent="submit" dense>
        <v-container>
          <v-row v-if="error">
            <v-col cols="12">
              <app-alert @dismissed="dismiss" :title="error.title" :text="error.message"></app-alert>
            </v-col>
          </v-row>
            <div class='headline'>Invite User</div>
            <v-row no-gutters>
              <v-col>
                <v-select
                    v-model="role"
                    :items="roles"
                    label="User Role"
                    item-value="value"
                    item-text="title"
                    :rules="[rules.required]"
                    dense
                    ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" >
                <v-text-field
                  v-model="firstName"
                  :rules="[rules.required]"
                  label="First name *"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" ml-5>
                <v-text-field
                v-model="lastName"
                :rules="[rules.required]"
                label="Last name *"
                required
              ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  :rules="[rules.required, rules.emailValid]"
                  label="E-mail *"
                  type="email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dismiss">Cancel</v-btn>
          <v-btn type="submit" class="primary" :disabled="loading" :loading="loading" form="newAccountForm">Submit</v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    valid: true,
    showDialog: false,
    rules: {
      required: v => !!v || 'Required.',
      agree: v => !!v || 'You must agree to continue!',
      min: v => (v && v.length >= 8) || 'Min 8 characters',
      emailValid: v => /.+@.+/.test(v) || 'E-mail must be valid'
    },
    firstName: '',
    lastName: '',
    email: '',
    role: ''
  }),
  mounted () {
    this.$store.dispatch('common/clearError')
  },
  watch: {
    showDialog (value) {
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.role = ''
      if (this.$refs.newAccountForm) {
        this.$refs.newAccountForm.reset()
      }
      if (value) {
        this.$store.dispatch('analytics/logScreen', { name: 'organisation-account-invite' })
      }
    }
  },
  computed: {
    roles () {
      const roles = []
      if (this.user.role === 'superuser') {
        roles.push({ value: 'developer', title: 'Developer' })
        roles.push({ value: 'system-admin', title: 'System Admin' })
        roles.push({ value: 'system-manager', title: 'System Manager - Manages Agencies, Tours & User Accounts' })
      }
      // if (this.$store.getters['auth/isSystemAdmin']) {
      // }
      // if (this.$store.getters['auth/isSystemManager']) {
      //   }
      // roles.push({ value: 'account-holder', title: 'Account Holder - Owns Agency account' })
      roles.push({ value: 'account-admin', title: 'Account Admin - Can invite other users' })
      roles.push({ value: 'account-default', title: 'Default User' })
      return roles
    },
    ...mapState('auth', ['user']),
    ...mapState('common', ['error', 'loading']),
    ...mapState('organisations', {
      organisation: 'loadedItem'
    })
  },
  methods: {
    submit () {
      if (this.$refs.newAccountForm.validate()) {
        const that = this
        this.$store.dispatch('organisationAccounts/create',
          {
            organisationId: this.organisation.id,
            payload: {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              role: this.role
            }
          })
          .then(resp => {
            that.showDialog = false
          })
        // this.$ga.event('user', 'invite')
      }
    },
    dismiss () {
      this.$refs.newAccountForm.reset()
      this.$store.dispatch('common/clearError')
      this.showDialog = false
    }
  }
}
</script>
