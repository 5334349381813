<template>
  <v-dialog v-model="dialogVisible" fullscreen persistent transition="dialog-bottom-transition">
    <div v-if="fullscreen" style="width:100%; height:100%;">
      <v-btn top fab fixed right small @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <iframe ref="previewFullscreen" class="firstvue-frame" :src="bustedURL" width="100%" height="100%"></iframe>
    </div>
    <v-card v-else height="100%" style="min-height: 100vh">
      <v-toolbar>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{title}}</v-toolbar-title>
      </v-toolbar>
      <iframe ref="preview" class="firstvue-frame" :src="bustedURL" width="100%" height="91%"></iframe>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: ['fullscreen', 'title', 'url', 'model', 'didClose'],
  data: () => ({
    dialogVisible: false
  }),
  watch: {
    model (value) {
      if (value) {
        // Reload the iframe to ensure we don't see a cached page
        if (this.$refs.preview) {
          this.$refs.preview.src = this.bustedURL
        }
        if (this.$refs.previewFullscreen) {
          this.$refs.previewFullscreen.src = this.bustedURL
        }
      }
      this.dialogVisible = value
    }
  },
  computed: {
    bustedURL () {
      const platform = window.localStorage.getItem('platform') || 'web'
      let url = this.url
      if (!url.includes('?')) {
        url += '?'
      }
      url += `&platform=${platform}&cb=${new Date().getTime()}`
      return url
    }
  },
  methods: {
    cancel () {
      this.$props.didClose()
    }
  }
}
</script>

<style>
iframe {
  border: none;
}
.v-dialog--fullscreen {
  background-color: #ffffff;
}
</style>
