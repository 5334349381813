<template>
  <v-alert type="error" @input="onClose" :value="true">
    <b>{{title}}:</b><br/>{{ text }}
  </v-alert>
</template>

<script>
export default {
  props: ['title', 'text'],
  methods: {
    onClose () {
      this.$emit('dismissed')
    }
  }
}
</script>
