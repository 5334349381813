<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="sourceTableHeaders"
        :items="userSourcesTableData"
        class="elevation-1"
        hide-default-footer
        sort-by="count"
        :sort-desc="[true]"
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Sources</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:[`item.source`]="props">
          {{props.item.source}}
          <!-- <v-progress-linear value="15"></v-progress-linear> -->
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    sourceTableHeaders: [
      { text: 'Source', align: 'left', sortable: true, value: 'source' },
      { text: 'Count', align: 'right', sortable: true, value: 'count', width: '20' }
    ]
  }),
  computed: {
    ...mapState('analyticsReports', ['userSourcesTableData'])
  }
}
</script>
