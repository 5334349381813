<template>
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6" md="4">
          <v-card>
            <v-card-text>
                <p class="mb-0 red--text headline"><b>Oops!</b></p>
                <p class="mb-0 red--text headline">{{title}}:</p>
                <p class="mb-0 red--text">{{message}}. Please refresh or try again later.</p>
            </v-card-text>
          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    title () {
      return this.$store.getters.globalError ? this.$store.getters.globalError.title : 'Unknown Error'
    },
    message () {
      return this.$store.getters.globalError ? this.$store.getters.globalError.message : 'Something\'s not right'
    }
  }
}
</script>
