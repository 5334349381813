import api from './API'

const ORGANISATIONS_URL = '/organisations'

export default {
  list: function (organisationId) {
    const url = `${ORGANISATIONS_URL}/${organisationId}/projects`
    return api.newRequest('get', url)
  },
  create: function (organisationId, payload) {
    const url = `${ORGANISATIONS_URL}/${organisationId}/projects`
    return api.newRequest('post', url, payload)
  },
  update: function (organisationId, projectId, payload) {
    const url = `${ORGANISATIONS_URL}/${organisationId}/projects/${projectId}`
    return api.newRequest('patch', url, payload)
  },
  delete: function (organisationId, projectId) {
    const url = `${ORGANISATIONS_URL}/${organisationId}/projects/${projectId}`
    return api.newRequest('delete', url)
  }
}
