import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import vuetify from './plugins/vuetify'

import AppDeleteConfirmationDialog from '@/components/app/DeleteConfirmationDialog.vue'

import EditAccountDetailsDialog from '@/views/accounts/EditAccountDetailsDialog'
import EditAccountExtraMenu from '@/views/accounts/EditAccountExtraMenu'
import EditAccountPasswordDialog from '@/views/accounts/EditAccountPasswordDialog'

import OrganisationAccountNewDialog from '@/views/organisations/OrganisationAccountNewDialog.vue'
import OrganisationAccountEditDialog from '@/views/organisations/OrganisationAccountEditDialog.vue'
import OrganisationAccountEditDialogExtraMenu from '@/views/organisations/OrganisationAccountEditDialogExtraMenu.vue'

import NotificationEditMenu from '@/views/notifications/NotificationEditMenu.vue'

import InsightsTabBar from '@/views/insights/InsightsTabBar.vue'

import ProjectTimelapseDialog from '@/views/projects/ProjectTimelapseDialog'

import AppGlobalError from '@/components/app/GlobalError'
import Alert from '@/components/app/Alert'
import AlertDialog from '@/components/app/AlertDialog'
import ConfirmationDialog from '@/components/app/ConfirmationDialog'
import DatetimePicker from '@/components/DatetimePicker'

import FormatDate from '@/filters/FormatDate'
import FormatDuration from '@/filters/FormatDuration'

import WebpageDialog from '@/views/WebpageDialog'

import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/dataZoom'

Vue.component('v-chart', ECharts)

Vue.filter('formatDate', FormatDate)
Vue.filter('formatDuration', FormatDuration)

Vue.component('organisation-account-new-dialog', OrganisationAccountNewDialog)
Vue.component('organisation-account-edit-dialog', OrganisationAccountEditDialog)
Vue.component('organisation-account-edit-dialog-extra-menu', OrganisationAccountEditDialogExtraMenu)

Vue.component('notification-edit-menu', NotificationEditMenu)

Vue.component('insights-tabbar', InsightsTabBar)

Vue.component('project-timelapse-dialog', ProjectTimelapseDialog)

Vue.component('app-global-error', AppGlobalError)
Vue.component('app-alert', Alert)
Vue.component('app-alert-dialog', AlertDialog)
Vue.component('app-confirmation-dialog', ConfirmationDialog)
Vue.component('datetime-picker', DatetimePicker)

Vue.component('app-edit-account-details-dialog', EditAccountDetailsDialog)
Vue.component('app-edit-account-extra-menu', EditAccountExtraMenu)
Vue.component('app-edit-password-dialog', EditAccountPasswordDialog)
Vue.component('app-delete-confirmation-dialog', AppDeleteConfirmationDialog)

Vue.component('webpage-dialog', WebpageDialog)

Vue.config.productionTip = false

function findGetParameter (parameterName) {
  let result = null
  let tmp = []
  location.search.substr(1).split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1])
    })
  return result
}

// const token = findGetParameter('token') || process.env.VUE_APP_API_TOKEN
// const env = findGetParameter('env') || 'dev'
// const fv = findGetParameter('fv') || 1
// window.localStorage.setItem('token', token)
// window.localStorage.setItem('env', env)
// window.localStorage.setItem('fv', fv)

// // this is null if we are viewing the app directly in the browser during development
if (findGetParameter('embedded') == null) {
  console.log('main.js :: embedded is null')
  window.localStorage.removeItem('embedded')
} else {
  console.log('main.js :: embedded is true')
  window.localStorage.setItem('embedded', true)
}
// console.log('Starting App:')
// console.log(`\tToken: ${token}`)
// console.log(`\tEnv: ${env}`)
// console.log(`\tFV: ${fv}`)
// console.log(`\tAPI URL: ${process.env.VUE_APP_API_URL}`)
console.log(navigator.userAgent)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    console.log('main.js created')
    // auto signin user that already has credentials stored in cookies
    const jwt = window.localStorage.getItem('jwt')
    const role = window.localStorage.getItem('role')
    if (jwt !== null && jwt !== 'undefined' && jwt !== '') {
      this.$store.dispatch('auth/autoSignIn', { token: jwt, role: role })
    }
  }
}).$mount('#app')
