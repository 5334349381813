<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Organisations</v-toolbar-title>
      <v-toolbar-items>
        <v-btn v-if="isAdminUser" text color="primary" dark @click="dialog = !dialog">
          <v-icon dark>mdi-plus</v-icon>Add Agency
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <app-delete-confirmation-dialog
      :model="deleteDialog"
      name="Agency"
      :didCancel="dismissDeleteDialog"
      :didDelete="deleteItem"
    ></app-delete-confirmation-dialog>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="editedItem.name"
              label="Agency Name"
              :rules="[rules.nameRequired]">
            </v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="close">Cancel</v-btn>
          <v-btn type="submit" class="primary" :disabled="loading" :loading="loading">Submit</v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.name`]="props">
          <router-link :to="'/organisations/' + props.item.id+ '/accounts'">{{ props.item.name }}</router-link>
        </template>
        <template v-slot:[`item.dateModified`]="props">
          {{ props.item.dateModified | formatDate }}
        </template>
        <template v-slot:no-data>
          You have not added any organisations
        </template>
      </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    valid: true,
    dialog: false,
    deleteDialog: false,
    itemToDelete: null,
    headers: [
      { text: 'Organisations', align: 'left', sortable: true, value: 'name' },
      { text: 'Last Modified', value: 'dateModified', align: 'right', sortable: true }
      // { text: 'Actions', align: 'centre', value: 'name', sortable: false }
    ],
    editedIndex: -1,
    editedItem: { name: '', dateModified: '' },
    defaultItem: { name: '', dateModified: '' },
    rules: {
      nameRequired: v => !!v || 'Agency name is required.'
    }
  }),

  created () {
    this.$store.dispatch('organisations/list')
    this.$store.dispatch('analytics/logScreen', { name: 'organisation-list' })
  },

  computed: {
    isAdminUser () {
      return this.$store.getters['auth/isSystemAdmin']
    },
    formTitle () {
      if (this.isAdminUser) {
        return this.editedIndex === -1 ? 'New Agency' : 'Edit Agency'
      }
      return this.editedIndex === -1 ? 'Invite User' : 'Edit User'
    },
    items () {
      return this.$store.getters['organisations/items']
    },
    ...mapState('common', ['error', 'loading'])
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  methods: {
    viewAccounts (item) {
      this.$router.push({ name: 'agency', params: { userId: 123 } })
    },

    editItem (item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    confirmDeleteItem (item) {
      this.itemToDelete = item
      this.deleteDialog = true
    },

    approve (item) {
      this.$store.dispatch('organisations/approve', item.id)
    },

    deleteItem () {
      this.$store.dispatch('organisations/delete', this.itemToDelete.id)
      this.dismissDeleteDialog()
      // this.$ga.event('organisations', 'delete')
    },
    dismissDeleteDialog () {
      this.itemToDelete = null
      this.deleteDialog = false
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    submit () {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.$store.dispatch('organisations/update', this.editedItem)
            .then(resp => {
              this.close()
            })
        } else {
          this.$store.dispatch('organisations/create', this.editedItem)
            .then(resp => {
              this.close()
            })
        }
      }
    }
  }
}
</script>
