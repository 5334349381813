import axios from 'axios'
import store from '@/store'

const HOST = process.env.VUE_APP_API_URL
// const API_TOKEN = process.env.VUE_APP_API_TOKEN

const API_URL = `${HOST}/v1`

// axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common['API-Token'] = API_TOKEN

function handleResponse (response, resolve, reject) {
  if (response.unauthorized) {
    reject(response.error)
  } else if (response.status === 'error') {
    reject(response.error)
  } else {
    resolve(response)
  }
}

export default {
  API_URL: `${HOST}/v1`,
  newRequest (method, url, payload) {
    const fullUrl = API_URL + url
    const headers = {
      'Content-Type': 'application/json',
      'API-Token': window.localStorage.getItem('token')
    }
    const token = window.localStorage.getItem('jwt')
    if (token) {
      headers.Authorization = 'JWT ' + token
    }
    return new Promise(function (resolve, reject) {
      axios({
        method: method,
        url: fullUrl,
        data: payload,
        headers: headers
      }).then((resp) => {
        console.log(resp.data)
        console.log(resp.status)
        console.log(resp.statusText)
        console.log(resp.headers)
        console.log(resp.config)
        handleResponse(resp.data, resolve, reject)
      }).catch((error) => {
        console.log(error)
        let errorResponse = { code: 0, title: 'Unkown API error', message: 'Unkown API error' }
        if (error.response) {
          if (error.response.status === 401) {
            // Sign out unauthorized tokens
            store.dispatch('auth/signout')
            // router.push('/login')
          } else if (error.response.data && error.response.data.status === 'error') {
            errorResponse = error.response.data.error
          }
        } else {
          errorResponse.title = error.message
          errorResponse.message = error.message
        }
        reject(errorResponse)
      })
    })
  },
  newUploadRequest (formData, uuid, didSetCancelToken) {
    const fullUrl = `${HOST}/upload?X-Progress-ID=${uuid}`
    // var token = window.localStorage.getItem('jwt')
    // if (token) {
    //   axios.defaults.headers.common['Authorization'] = 'JWT ' + token
    // }
    const CancelToken = axios.CancelToken

    return new Promise(function (resolve, reject) {
      axios({
        method: 'post',
        url: fullUrl,
        data: formData,
        config: {
          headers: { 'Content-Type': 'multipart/form-data' }
        },
        cancelToken: new CancelToken(function executor (c) {
          didSetCancelToken(c)
        })
      }).then((resp) => {
        console.log(resp)
        handleResponse(resp.data, resolve, reject)
      }).catch((error) => {
        console.log(error)
        let errorResponse = { code: 0, title: 'Unkown API error', message: 'Unkown API error' }
        if (error.response) {
          if (error.response.status === 401) {
            // Sign out unauthorized tokens
            store.dispatch('signout')
            // router.push('/login')
          } else if (error.response.data && error.response.data.status === 'error') {
            errorResponse = error.response.data.error
          }
        } else {
          errorResponse.title = error.message
          errorResponse.message = error.message
        }
        reject(errorResponse)
      })
    })
  }
}
