// import * as firebase from 'firebase'
import authApi from '@/api/AuthAPI'
import accountAPI from '@/api/AccountAPI'
import router from '@/router'

const state = {
  user: null,
  introQuestions: null
}

const mutations = {
  setUser (state, payload) {
    console.log('commit set user')
    console.log(payload)
    state.user = payload

    const platform = window.localStorage.getItem('platform')
    if (payload !== null) {
      const jwt = window.localStorage.getItem('jwt')
      // If desktop app, make direct call to registered functions
      if (platform === 'osx' || platform === 'win') {
        const jsonString = JSON.stringify({
          token: jwt,
          userId: payload.id
        })
        window.fvSetUser(jsonString)
      } else {
        // On mobile, user url scheme
        window.location.href = `uniwebview://set-user?token=${jwt}&userId=${payload.id}`
      }
    } else {
      // Sign out user if payload is null
      if (platform === 'osx' || platform === 'win') {
        window.fvLogout()
      } else {
        window.location.href = 'uniwebview://logout'
      }
    }
  },
  setIntroQuestions (state, payload) {
    state.introQuestions = payload
  }
}

const getters = {
  isAdminUser (state) {
    return state.user.role === 'admin' || state.user.role === 'dev'
  },
  isATTUser (state) {
    return state.user.role === 'admin' || state.user.role === 'dev' || state.user.role === 'atntbasic'
  },
  isManagerUser (state) {
    return state.user.role === 'manager'
  },
  isAccountAdmin (state) {
    if (state.user) {
      return state.user.role === 'account-admin' || state.user.role === 'system-admin' || state.user.role === 'superuser'
    }
    return false
  },
  isAuthenticated (state) {
    return state.user !== null && state.user !== undefined
  }
}

const actions = {
  signup ({ commit, dispatch }, payload) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    authApi.signup(
      payload.firstName,
      payload.lastName,
      payload.email,
      payload.password,
      payload.organisation,
      payload.phone,
      payload.agreed)
      .then(resp => {
        console.log('done!')
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        // router.push('/registration/complete')
        // Log the user right in
        const newUser = {
          id: resp.data.account.id,
          firstName: resp.data.user.firstName,
          lastName: resp.data.user.lastName,
          phone: resp.data.user.phone,
          role: resp.data.user.role,
          token: resp.data.token,
          organisation: resp.data.organisation.name,
          organisationId: resp.data.organisation.id,
          fbKeys: {}
        }
        commit('setUser', newUser)
        dispatch('auth/loadUserData', {}, { root: true })
        router.push('/')
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  signin ({ commit, dispatch }, payload) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    authApi.login(payload.email, payload.password, '')
      .then(resp => {
        console.log('done!')
        commit('common/setLoading', false, { root: true })
        const newUser = {
          id: resp.data.account.id,
          firstName: resp.data.user.firstName,
          lastName: resp.data.user.lastName,
          email: resp.data.user.email,
          phone: resp.data.user.phone,
          role: resp.data.user.role,
          token: resp.data.token,
          organisation: resp.data.organisation.name,
          organisationId: resp.data.organisation.id,
          fbKeys: {}
        }
        commit('setUser', newUser)
        dispatch('auth/loadUserData', {}, { root: true })
        // if (router.params.nextUrl != null) {
        //   router.push(router.params.nextUrl)
        // } else {
        // router.push('/')
        // }
        router.push('/')
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  loadUserData ({ commit, dispatch }, payload) {
    console.log('store/user :: loadUserData ')
    commit('common/setLoading', true, { root: true })
    accountAPI.view('me')
      .then(resp => {
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        const userData = {
          id: resp.data.account.id,
          firstName: resp.data.account.firstName,
          lastName: resp.data.account.lastName,
          phone: resp.data.account.phone,
          email: resp.data.account.email,
          role: resp.data.account.role,
          organisation: resp.data.organisation.name,
          organisationId: resp.data.organisation.id
        }
        commit('setUser', userData)
        commit('setIntroQuestions', resp.data.introQuestions)
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
        // Log the user out of any error happens here
        dispatch('signout')
      })
  },
  autoSignIn ({ commit, dispatch }, payload) {
    commit('setUser', {
      token: payload.token,
      role: payload.role
    })
    dispatch('auth/loadUserData', {}, { root: true })
    // router.push('/')
  },
  signout ({ commit }) {
    console.log('AuthStore :: signout')
    window.localStorage.removeItem('jwt')
    commit('setUser', null)
    const isFVC = window.localStorage.getItem('type') === 'fvc'
    if (isFVC) {
      router.push('/')
    } else {
      router.push('/welcome')
    }
  },
  resetPassword ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    return new Promise((resolve, reject) => {
      authApi.resetPassword(payload.email)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          resolve()
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
          reject(error)
        })
    })
  },
  validateResetPasswordHash ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    return new Promise((resolve, reject) => {
      authApi.validateResetPasswordHash(payload.hash)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          resolve()
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
          reject(error)
        })
    })
  },
  setNewPasswordHash ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    return new Promise((resolve, reject) => {
      authApi.setNewPassword(payload.hash, payload.password)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          resolve()
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
          reject(error)
        })
    })
  },
  confirmEmail ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    return new Promise((resolve, reject) => {
      authApi.confirmEmail(payload.hash)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          resolve()
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
          reject(error)
        })
    })
  },
  validateInvitationHash ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    return new Promise((resolve, reject) => {
      authApi.validateInvitationHash(payload.hash)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
          reject(error)
        })
    })
  },
  acceptInvitation ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    return new Promise((resolve, reject) => {
      authApi.acceptInvitation(payload.hash, payload.password)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
          reject(error)
        })
    })
  },
  clearIntroQuestions ({ commit }) {
    commit('setIntroQuestions', null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
