
<template>
  <v-dialog v-model="dialogVisible" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card height="100%">
      <v-toolbar>
        <v-btn icon @click="dismiss">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Timelapse</v-toolbar-title>
      </v-toolbar>
      <iframe style="width: 100%; min-height:90%;" ref="preview" class="firstvue-timelapse" :src="srcURL"></iframe>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    dialog: Boolean,
    srcURL: String,
    didDismiss: Function
  },
  data: () => ({
    dialogVisible: false,
    sound: null,
    notifications: null,
    showHotspotEditDialog: false,
    mouseCoordinates: []
  }),
  computed: {
  },
  watch: {
    dialog (value) {
      if (value) {
        this.dialogVisible = value
      }
    }
  },
  created () {
  },
  methods: {
    dismiss () {
      this.$props.didDismiss()
    }
  }
}
</script>
