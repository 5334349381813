<template>
  <v-container fluid v-if="config">
    <v-row no-gutters>
      <v-col cols="12">
        <v-img :src="getImageSrc" aspect-ratio="2"></v-img>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <div class="text-h4 mt-2">{{propertyDeveloperName}}</div><br>
        <div class="text-h5 mb-2">{{config.subtitle}}</div>
        <div class="text-body-1" v-html="config.description"></div>
        <div class="text-body-2 mt-2">{{config.address}}</div>
        <div class="text-center mt-5">
          <v-btn v-for="item in config.socials" class="mx-2" text :key="item.name" :href="item.url">
            <v-icon>mdi-{{item.name}}</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    propertyDeveloperName () {
      return this.propertyDeveloper.name || ''
    },
    getImageSrc (project) {
      return this.config.aboutImageURL ? `${this.assetServerURL}${this.config.aboutImageURL}` : ''
    },
    propertyDeveloper () {
      return this.$store.getters['organisations/loadedItem']
    },
    ...mapState('organisations', {
      config: 'config',
      propertyDeveloper: 'loadedItem'
    }),
    ...mapGetters('app', ['assetServerURL'])
  },
  created () {
    console.log('About.vue')
    this.$store.dispatch('organisations/about', this.$route.params.id)
    this.$store.dispatch('analytics/logScreen', { name: 'organisation-about', data: { organisationName: this.propertyDeveloperName } })
  }
}
</script>
