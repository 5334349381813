<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-chart
        :options="userChartData"
        ref="bar"
        theme="ovilia-green"
        autoresize
      />
    </v-col>
    <v-col cols="12" md="6">
      <v-chart
        :options="sessionsChartData"
        autoresize
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    userChartData: {
      color: ['#6a81ff'],
      legend: {},
      tooltip: { trigger: 'axis' },
      title: { text: 'Users', right: 'center' },
      dataset: {
        // Provide data.
        source: []
      },
      // Declare X axis, which is a category axis, mapping
      // to the first column by default.
      xAxis: { type: 'category' },
      // Declare Y axis, which is a value axis.
      yAxis: {},
      // Declare several series, each of them mapped to a
      // column of the dataset by default.
      series: [{ type: 'line' }],
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          }
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 70,
          end: 100
        },
        {
          start: 70,
          end: 100,
          handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }
      ]
    },
    sessionsChartData: {
      color: ['#6a81ff'],
      legend: {},
      tooltip: { trigger: 'axis' },
      title: { text: 'Sessions', right: 'center' },
      dataset: {
        // Provide data.
        source: []
      },
      // Declare X axis, which is a category axis, mapping
      // to the first column by default.
      xAxis: { type: 'category' },
      // Declare Y axis, which is a value axis.
      yAxis: {},
      // Declare several series, each of them mapped to a
      // column of the dataset by default.
      series: [{ type: 'line' }],
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          }
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 70,
          end: 100
        },
        {
          start: 70,
          end: 100,
          handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }
      ]
    }
  }),
  computed: {
    ...mapState('analyticsReports', ['usersReport', 'users', 'userCount', 'sessionsByDate', 'sessionsByUser']),
    ...mapState('common', ['error', 'loading'])
  },
  mounted () {
    this.userChartData.dataset.source = this.usersReport
    this.userChartData.title.text = `Users (${this.userCount})`
    this.sessionsChartData.dataset.source = this.sessionsByDate
    // const bar = this.$refs.bar
    // const that = this
    // bar.showLoading({
    //   text: 'Loading…',
    //   color: '#4ea397',
    //   maskColor: 'rgba(255, 255, 255, 0.4)'
    // })

    // // const diffTime = Math.abs(date2 - date1)
    // // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    // // console.log(diffTime + " milliseconds")

    // this.$store.dispatch('analyticsReports/getSessionsReport')
    //   .then(resp => {
    //     console.log('send ids to get App User details')
    //     var ids = []
    //     resp.data.identities.forEach(identity => {
    //       ids.push(identity.identity)
    //     })

    //     that.$store.dispatch('analyticsReports/getAppUsers', ids)
    //       .then(resp => {
    //         bar.hideLoading()
    //         that.userChartData.dataset.source = that.usersReport
    //         that.userChartData.title.text = `Users (${that.userCount})`
    //         that.sessionsChartData.dataset.source = that.sessionsByDate
    //         that.lastUpdate = new Date()
    //       })
    //       .catch(error => {
    //         bar.hideLoading()
    //         console.log(error)
    //       })
    //   })
    //   .catch(error => {
    //     bar.hideLoading()
    //     console.log(error)
    //   })
  }
}
</script>
