<template>
  <v-container fluid>
    <v-tabs v-model="tabValue" color="primary" slider-color="primary" centered>
      <v-tab exact to="/insights/users/accounts/" ripple>Accounts</v-tab>
      <v-tab exact to="/insights/users/sources/" ripple>Sources</v-tab>
      <v-tab exact to="/insights/users/interests/" ripple>Interests</v-tab>
      <v-tab-item value="/insights/users/accounts/" :transition="false" :reverse-transition="false">
        <accounts-tab></accounts-tab>
      </v-tab-item>
      <v-tab-item value="/insights/users/sources/" :transition="false" :reverse-transition="false">
        <sources-tab></sources-tab>
      </v-tab-item>
      <v-tab-item value="/insights/users/interests/" :transition="false" :reverse-transition="false">
        <interests-tab></interests-tab>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AccountsTab from './tabs/AccountsTab'
import InterestsTab from './tabs/InterestsTab'
import SourcesTab from './tabs/SourcesTab'
export default {
  components: {
    AccountsTab,
    InterestsTab,
    SourcesTab
  },
  data: () => ({
    tabValue: ''
  }),
  computed: {
    ...mapState('common', ['error', 'loading'])
  },
  mounted () {
  },
  created () {
    this.tabValue = this.$route.params.tab
  },
  methods: {
  }
}
</script>
