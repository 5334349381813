<template>
  <v-container fluid>
    <!-- <v-app-bar app elevate-on-scroll color="white">
      <v-btn icon @click="$router.go(-1)">
        <v-icon dark>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Register</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar> -->
    <div v-if="!isFVA" >
      <v-row justify="center" class="text-center">
        <v-col cols="12" sm="6" md="4">
          <v-card elevation="0" outlined color="transparent">
            <v-card-text>
              <v-form id="registration-form" ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="firstName"
                  :rules="[rules.required]"
                  label="First name *"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="lastName"
                  :rules="[rules.required]"
                  label="Last name *"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  :rules="[rules.required, rules.emailValid]"
                  label="E-mail *"
                  required
                  type="email"
                ></v-text-field>
                <v-text-field
                  v-if="signupExtraFields && signupExtraFields.phone"
                  v-model="phone"
                  :rules="[rules.required]"
                  label="Phone *"
                  required
                  type="tel"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :rules="[rules.required, rules.min]"
                  type="password"
                  name="password"
                  label="Password *"
                  hint="At least 8 characters"
                  counter
                ></v-text-field>
                <v-checkbox
                  v-model="agreed"
                  :rules="[rules.agree]"
                  required
                  hide-details
                  class="mb-5"
                >
                <template v-slot:label>
                  <div>
                  By creating a FirstVue&trade; account, I agree to the <a @click="showTerms">Terms and Conditions</a>
                  and <a @click="showPrivacyPolicy">Privacy Policy</a>
                  </div>
                </template>
                </v-checkbox>
              </v-form>
              <v-btn
                  color="primary"
                  @click="submit"
                  :disabled="loading"
                  :loading="loading"
                  form="registration-form"
                  class="mt-5"
                  >
                  submit
                </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="error" align="center" justify="center">
        <v-col cols="12" sm="6">
          <app-alert @dismissed="onDismissed" :title="error.title" :text="error.message"></app-alert>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mb-10">
        <v-col cols="12" sm="6">
            <v-divider class="mb-2"></v-divider>
            <div class="text-center mb-2">Already have a <b>FirstVue&trade; Account</b>?</div>
            <div class="text-center"><v-btn color="primary" outlined rounded :to="'/fvclogin'">Sign in</v-btn></div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row justify="center" class="text-center">
        <v-col cols="12">
          <v-card elevation="0" outlined color="transparent">
            <v-card-text>
              <div class="title">Access to <b>FirstVue Agent</b> is by invitation only.</div>
              <div class="subtitle">To request access, please email support[at]limpidproperty.com</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <webpage-dialog
      :title="webpageTitle"
      :model="showWebpageDialog"
      :url="webpageURL"
      :fullscreen="webpageIsFullscreen"
      :didClose="didCloseWebpage">
    </webpage-dialog>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      valid: true,
      rules: {
        required: v => !!v || 'Required.',
        agree: v => !!v || 'You must agree to continue!',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailValid: v => /.+@.+/.test(v) || 'E-mail must be valid'
      },
      organisation: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      agreed: false,
      password: '',
      confirmPassword: '',
      webpageURL: '',
      webpageTitle: '',
      webpageIsFullscreen: true,
      showWebpageDialog: false
    }
  },
  mounted () {
    this.$store.dispatch('common/clearError')
  },
  created () {
    // this.$store.dispatch('app/hideNav', true)
    this.$store.dispatch('analytics/logScreen', { name: 'auth-registration' })
  },
  computed: {
    comparePasswords () {
      return this.password !== this.confirmPassword ? 'Passwords do not match' : true
    },
    user () {
      return this.$store.getters.user
    },
    ...mapState('common', ['error', 'loading']),
    ...mapState('app', ['signupExtraFields']),
    ...mapGetters('app', ['isFVA'])
  },
  watch: {
    user (value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/')
      }
    }
  },
  methods: {
    submit () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('auth/signup', {
          organisation: this.organisation,
          agreed: this.agreed,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          password: this.password
        })
      }
    },
    onDismissed () {
      this.$store.dispatch('common/clearError', null, { root: true })
    },
    didCloseWebpage () {
      this.showWebpageDialog = false
    },
    showTerms () {
      this.webpageURL = 'https://firstvue.io/terms-and-conditions/'
      this.showWebpageDialog = true
    },
    showPrivacyPolicy () {
      this.webpageURL = 'https://firstvue.io/privacy-policy/'
      this.showWebpageDialog = true
    }
  }
}
</script>
