import analyticsAPI from '@/api/AnalyticsAPI'

const state = {
}

const getters = {
}

const actions = {
  logScreen ({ commit }, { name, data }) {
    analyticsAPI.logScreen(name, data)
      .then(resp => {
        console.log(resp)
      })
      .catch(error => {
        console.log(error)
      })
  },
  logEvent ({ commit }, { name, data }) {
    analyticsAPI.logEvent(name, data)
      .then(resp => {
        console.log(resp)
      })
      .catch(error => {
        console.log(error)
      })
  }
}
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
