<template>
  <v-col class="tile" cols="12" sm="6" xl="3">
    <v-carousel
      cycle
      height="188"
      :show-arrows="false"
      hide-delimiters
      color="red"
      class="tile-carousel"
      interval="4000"
    >
      <v-carousel-item v-for="slide in item.carousel.items" :key="slide.id" @click="selectItem(item)">
        <v-sheet height="100%">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-img :src="getImageSrc(slide.backgroundURL)" height="200"></v-img>
            <div class="tile-header-title">{{slide.title}}</div>
            <v-img :src="getImageSrc(slide.foregroundURL)" contain class="carousel-content"></v-img>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-col cols="12" class="tile-footer-panel" :style="getTileFooterStyle(item)">
      <v-avatar width="50" height="50" class="float-left mr-4">
        <v-img
          :src="getImageSrc(item.iconURL)"
          class="white--text align-end"
          height="50px"
        >
        </v-img>
      </v-avatar>
      <v-row no-gutters>
        <v-col class="pr-5">
          <div class="subtitle-1" :style="getTileFooterTitleStyle(item)">{{item.title}}</div>
          <div class="mb-2 subtitle-2 line-clamp" :style="getTileFooterSubtitleStyle(item)">{{item.subtitle}}</div>
        </v-col>
      </v-row>
    </v-col>
    <v-img v-if="item.type === 'video'" class="play-icon" :src="require('../../assets/button-play.png')" width="80px"></v-img>
    <webpage-dialog
      :title="webpageTitle"
      :model="showWebpageDialog"
      :url="webpageURL"
      :fullscreen="webpageIsFullscreen"
      :didClose="didCloseWebpage">
    </webpage-dialog>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['item'],
  data: () => ({
    webpageURL: '',
    webpageTitle: '',
    webpageIsFullscreen: true,
    showWebpageDialog: false
  }),
  computed: {
    ...mapGetters('app', ['assetServerURL'])
  },
  methods: {
    getImageSrc (url) {
      return `${this.assetServerURL}${url}`
    },
    getTileFooterTitleStyle (item) {
      const colour = item.footerTitleColour ?? 'black'
      return `color: ${colour}`
    },
    getTileFooterSubtitleStyle (item) {
      const colour = item.footerSubtitleColour ?? '#757575'
      return `color: ${colour}`
    },
    getTileFooterStyle (item) {
      const colour = item.footerBackgroundColour ?? '#f7f7f7'
      return `background-color: ${colour}`
    },
    selectItem (item) {
      let url = `/embedded-video.html?v=${encodeURIComponent(item.videoId)}`
      if (item.source) {
        url += `&s=${item.source}`
      }
      this.webpageURL = url
      this.showWebpageDialog = true
    },
    didCloseWebpage () {
      this.showWebpageDialog = false
      this.webpageURL = ''
    }
  }
}
</script>

<style scoped>
  .play-icon {
    position: absolute;
    top: 35%;
    left: 50%;
    transform:translate(-50%, -50%);
    pointer-events: none;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .tile {
    position: relative;
  }
  .tile-header-title {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 13pt;
    color: white;
  }
  .tile-headline {
    position: absolute;
    left: 10px;
    top: 40px;
    color: white;
    font-size: 24pt;
    font-weight: bold;
    line-height: 28pt;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  }
  .tile-carousel {
    border-radius: 10px 10px 0 0;
  }
  .tile-footer-panel {
    border-radius: 0 0 10px 10px;
  }
</style>
