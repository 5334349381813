<template>
  <v-dialog width="550px" persistent v-model="editDialog">
    <template v-slot:activator="{ on }">
      <v-btn text fab v-on="on" small>
        <v-icon>mdi-account-edit</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">Edit Account</v-card-title>
      <v-divider></v-divider>
      <v-form id="form" ref="form" v-model="valid" lazy-validation>
        <v-row no-gutters>
          <v-col cols="12">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="firstName"
                      label="First Name"
                      id="firstName"
                      :rules="[rules.required]"
                      v-model="editedFirstName"
                      required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      name="lastName"
                      label="Last Name"
                      id="lastName"
                      :rules="[rules.required]"
                      v-model="editedLastName"
                      required>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      name="email"
                      label="Email"
                      id="email"
                      type="email"
                      :rules="[rules.required, rules.emailValid]"
                      v-model="editedEmail"
                      required>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
          </v-col>
        </v-row>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="editDialog = false">Cancel</v-btn>
        <v-btn @click="onSaveChanges" class="primary">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['account'],
  data () {
    return {
      valid: true,
      rules: {
        required: v => !!v || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailValid: v => /.+@.+/.test(v) || 'E-mail must be valid'
      },
      editDialog: false,
      editedFirstName: this.account.firstName,
      editedLastName: this.account.lastName,
      editedEmail: this.account.email
    }
  },
  computed: {
  },
  watch: {
    editDialog (value) {
      // Reset the values when the dialog is shown
      if (value) {
        this.editedFirstName = this.account.firstName
        this.editedLastName = this.account.lastName
        this.editedEmail = this.account.email
      }
    }
  },
  methods: {
    onSaveChanges () {
      if (this.$refs.form.validate()) {
        this.editDialog = false
        this.$store.dispatch('accounts/update', {
          id: this.account.id,
          firstName: this.editedFirstName,
          lastName: this.editedLastName,
          email: this.editedEmail
        })
      }
    }
  }
}
</script>
