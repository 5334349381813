import organisationAPI from '@/api/OrganisationAPI'

const state = {
  items: [],
  loadedItem: {},
  config: null
}

const getters = {
  items (state) {
    return state.items.sort((itemA, itemB) => {
      return itemA.dateModified > itemB.dateModified
    })
  }
}

const actions = {
  list ({ commit }) {
    commit('common/setLoading', true, { root: true })
    organisationAPI.list()
      .then(resp => {
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        commit('setItems', resp.data.organisations)
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  create ({ commit, getters }, payload) {
    commit('common/setLoading', true, { root: true })
    return new Promise((resolve, reject) => {
      organisationAPI.create(payload)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          commit('common/clearError', null, { root: true })
          commit('addItem', resp.data)
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  },
  update ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    return new Promise((resolve, reject) => {
      organisationAPI.update(payload)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          commit('setItem', resp.data.organisation)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
        })
    })
  },
  delete ({ commit }, itemId) {
    organisationAPI.delete(itemId)
      .then(resp => {
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        commit('delete', itemId)
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  view ({ commit }, id) {
    organisationAPI.view(id)
      .then(resp => {
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        commit('setLoadedItem', resp.data.organisation)
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  about ({ commit }, id) {
    commit('common/setLoading', true, { root: true })
    organisationAPI.about(id)
      .then(resp => {
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        commit('setConfig', resp.data.config)
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  contact ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    return new Promise((resolve, reject) => {
      organisationAPI.contact(payload)
        .then(resp => {
          console.log(resp)
          commit('common/setLoading', false, { root: true })
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
          reject(error)
        })
    })
  }
}

const mutations = {
  setItems (state, items) {
    state.items = items
  },
  addItem (state, payload) {
    state.items.push(payload)
  },
  setItem (state, payload) {
    const item = state.items.find(item => {
      return item.id === payload.id
    })
    item.name = payload.name
    item.isApproved = payload.isApproved
    item.dateModified = payload.dateModified
  },
  setLoadedItem (state, payload) {
    state.loadedItem = payload
  },
  delete (state, itemId) {
    state.items = state.items.filter(function (item) { return item.id !== itemId })
  },
  setConfig (state, payload) {
    state.config = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
