<template>
  <div v-if="appLayout == 'default'">
    <home-default-layout></home-default-layout>
  </div>
  <div v-else-if="appLayout == 'fva'">
    <home-fva-layout></home-fva-layout>
  </div>
  <div v-else>
    <v-container class="grey lighten-3">
      <v-row v-if="projects.length == 0 && !loading">
        <v-col
          cols="12"
        >
        <v-card outlined>
          <v-card-text class="text-center">
            You don't have any projects assigned to your account. Please contact support@limpidproperty.com
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          v-for="project in projects"
          :key="project.name"
          cols="12" sm="5"
        >
          <v-card v-if="project.type === 'banner'" outlined class="mb-1">
            <v-img
              contain
              :src="getImageSrc(project)"
              class="white--text align-end"
              height="200px"
              min-height="200px"
            >
            </v-img>
          </v-card>
          <v-card v-else outlined class="mb-1">
            <v-img
              :src="getImageSrc(project)"
              class="white--text align-end"
              height="200px"
              @click="explore(project)"
            >
            </v-img>
            <v-img v-if="!project.isPublished" class="dev-ribbon" :src="require('../assets/dev-ribbon.png')" width="80px"></v-img>
            <v-img v-if="!project.config.projectBannerHidden" :src="getBannerSrc(project)" class="white--text align-end"></v-img>
            <v-card-title v-if="!project.config.titleHidden" class="pt-2">{{project.name}}</v-card-title>
            <v-card-subtitle class="pb-0">{{project.address}}</v-card-subtitle>
            <v-card-text class="text--primary pb-0" v-html="project.description"></v-card-text>
            <v-expand-transition>
              <div v-show="project.infoExpanded">
                <v-divider></v-divider>
                <v-card-text v-html="project.description"></v-card-text>
              </div>
            </v-expand-transition>
              <v-divider class="mt-2"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="explore(project)">
                <v-icon class="mr-2" v-if="getPrimaryButtonIcon(project)">{{getPrimaryButtonIcon(project)}}</v-icon> {{getPrimaryButtonLabel(project)}}
              </v-btn>
              <v-btn v-if="project.config.timelapseEnabled" class="ml-2" outlined @click="viewTimelapse(project)">Timelapse</v-btn>
              <v-spacer></v-spacer>
              <!-- <v-btn icon>
                <v-icon>mdi-heart-outline</v-icon>
              </v-btn> -->
              <!-- <v-btn text @click="project.infoExpanded = !project.infoExpanded">
                Read More
                <v-icon>{{ project.infoExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="activityDialog"
        persistent
        width="300"
      >
        <v-card
          color="gray"
          dark
          class="pt-2"
        >
          <v-card-text class="text-center">
            {{ activityDialogMessage }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <project-timelapse-dialog
        :dialog="showTimelapseDialog"
        :srcURL="timelapseSrcURL"
        :didDismiss="didDismissTimelapseDialog">
      </project-timelapse-dialog>
      <webpage-dialog
        :title="webpageTitle"
        :model="showWebpageDialog"
        :url="webpageURL"
        :fullscreen="webpageIsFullscreen"
        :didClose="didCloseWebpage">
      </webpage-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import HomeDefaultLayout from './HomeDefaultLayout'
import HomeFvaLayout from './HomeFvaLayout'

export default {
  components: {
    HomeDefaultLayout,
    HomeFvaLayout
  },
  computed: {
    projects () {
      return this.$store.getters['projects/items']
    },
    ...mapState('app', ['appLayout']),
    ...mapState('common', ['loading']),
    ...mapGetters('app', ['isFVC', 'assetServerURL']),
    ...mapGetters('auth', ['isAuthenticated'])
  },
  data: () => ({
    showWebpageDialog: false,
    showTimelapseDialog: false,
    timelapseSrcURL: '',
    webpageURL: '',
    webpageTitle: '',
    webpageIsFullscreen: true,
    activityDialog: false,
    activityDialogMessage: 'Let there be light...'
  }),
  created () {
    this.$store.dispatch('projects/list')
    this.$store.dispatch('analytics/logScreen', { name: 'home' })
  },
  methods: {
    getPrimaryButtonLabel (project) {
      if (project.type === 'webpage') {
        if (project.config.primaryButtonLabel) {
          return project.config.primaryButtonLabel
        } else {
          return 'Visit'
        }
      } else {
        return 'Explore'
      }
    },
    getPrimaryButtonIcon (project) {
      let icon = null
      if (project.type === 'webpage') {
        if (project.config.primaryButtonIcon) {
          icon = project.config.primaryButtonIcon
        } else {
          icon = 'mdi-link'
        }
      }
      return icon
    },
    getBannerSrc (project) {
      let bannerURL = ''
      if (project.config.projectBannerURL) {
        bannerURL = project.config.projectBannerURL
      } else if (project.org.config.projectBannerURL) {
        bannerURL = project.org.config.projectBannerURL
      }
      return `${this.assetServerURL}${bannerURL}`
    },
    getImageSrc (project) {
      return `${this.assetServerURL}${project.config.projectThumbnailURL}`
    },
    explore (project) {
      if (project.type !== 'webpage') {
        if (this.isAuthenticated) {
          this.activityDialog = true
          this.$store.dispatch('analytics/logEvent', { name: 'select-project', data: { projectId: project.id, projectName: project.name } })

          const platform = window.localStorage.getItem('platform')
          if (platform === 'osx' || platform === 'win') {
            const jsonString = JSON.stringify({
              projectId: project.id
            })
            window.fvLoadSceneForProjectId(jsonString)
          } else {
            window.location.href = `uniwebview://firstvue-scene?id=${project.id}`
          }
        } else {
          if (this.isFVC) {
            this.$router.push('/fvclogin')
          }
        }
      } else {
        this.showWebpageDialog = true
        this.webpageTitle = project.name
        this.webpageURL = project.config.webpageURL
        this.webpageIsFullscreen = project.config.webpageIsFullscreen
        this.$store.dispatch('analytics/logEvent', { name: 'select-webpage', data: { projectId: project.id, projectName: project.name, url: project.config.webpageURL, fullscreen: project.config.webpageIsFullscreen } })
      }
    },
    viewTimelapse (project) {
      this.showTimelapseDialog = true
      this.timelapseSrcURL = project.config.timelapseURL
      this.$store.dispatch('analytics/logEvent', { name: 'select-project-timelapse', data: { projectId: project.id, projectName: project.name } })
    },
    didDismissTimelapseDialog () {
      this.showTimelapseDialog = false
    },
    didCloseWebpage () {
      this.showWebpageDialog = false
    }
  }
}
</script>

<style scoped>
  .v-item-group.v-bottom-navigation .v-btn.v-size--default {
    height: inherit;
  }
  .dev-ribbon {
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
