<template>
  <v-container fluid>
    <v-layout align-center justify-center>
        <v-flex xs12 sm6 md4 lg3 xl2 v-if="error">
            <v-card class="mb-4">
                <v-card-text>
                <p class="mb-0 red--text"><b>{{error.title}}:</b> {{error.message}}</p>
                </v-card-text>
            </v-card>
        </v-flex>
      <v-flex xs12 sm6 md4 lg3 xl2 v-if="isValid">
        <div class="headline mb-4 text-center">Email Confirmed</div>
        <v-card>
          <v-card-text>
            <p>Thank you for confirming your email. You can now login and start creating your VNFs.</p>
            <v-btn block type="submit" to="/login" color="primary">Log in</v-btn>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    isValid: false
  }),
  computed: {
    ...mapState('common', ['error', 'loading'])
  },
  created () {
    const hash = this.$route.params.hash
    this.$store.dispatch('confirmEmail', { hash: hash }).then(() => {
      this.isValid = true
    }).catch((error) => {
      console.log(error)
      this.isValid = false
    })
  }
}
</script>
