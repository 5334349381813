<template>
  <v-container fluid>
    <v-container style="height: 400px;" v-if="isLoading">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="subtitle-1 text-center"
          cols="12"
        >
          Loading Insights...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <router-view v-else></router-view>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    isLoading: true
  }),
  computed: {
    ...mapState('analyticsReports', ['isLoadingReports', 'usersReport', 'users', 'userCount', 'sessionsByDate', 'sessionsByUser']),
    ...mapState('common', ['error'])
  },
  created () {
    const that = this
    this.isLoading = true

    this.$store.dispatch('analyticsReports/getSessionsReport')
      .then(resp => {
        console.log('send ids to get App User details')
        const ids = []
        resp.data.identities.forEach(identity => {
          ids.push(identity.identity)
        })

        that.$store.dispatch('analyticsReports/getAppUsers', ids)
          .then(resp => {
            that.userChartData.dataset.source = that.usersReport
            that.userChartData.title.text = `Users (${that.userCount})`
            that.sessionsChartData.dataset.source = that.sessionsByDate
            that.isLoading = false
          })
          .catch(error => {
            that.isLoading = false
            console.log(error)
          })
      })
      .catch(error => {
        console.log(error)
        that.isLoading = false
      })
  },
  methods: {
  }
}
</script>
