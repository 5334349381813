<template>
  <v-col class="tile" cols="12" sm="4" xl="3">
    <v-carousel
      cycle
      height="150"
      :show-arrows="false"
      hide-delimiters
      color="red"
      class="tile-carousel"
      interval="4000"
    >
      <v-carousel-item @click="selectItem(item)">
        <v-sheet height="100%">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-img :src="getImageSrc(item.thumbnailURL)" height="200"></v-img>
            <!-- <div class="tile-header-title">{{item.title}}</div> -->
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-col cols="12" class="tile-footer-panel">
      <v-row no-gutters>
        <v-col>
          <div class="caption text-center">{{item.title}}</div>
        </v-col>
      </v-row>
    </v-col>
    <v-img v-if="item.type === 'video'" class="play-icon" :src="require('../../assets/button-play.png')" width="80px"></v-img>
    <webpage-dialog
      :title="webpageTitle"
      :model="showWebpageDialog"
      :url="webpageURL"
      :fullscreen="webpageIsFullscreen"
      :didClose="didCloseWebpage">
    </webpage-dialog>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['item'],
  data: () => ({
    webpageURL: '',
    webpageTitle: '',
    webpageIsFullscreen: true,
    showWebpageDialog: false
  }),
  computed: {
    ...mapGetters('app', ['assetServerURL'])
  },
  methods: {
    getImageSrc (url) {
      return `${this.assetServerURL}${url}`
    },
    selectItem (item) {
      this.webpageURL = `/embedded-video.html?v=${item.videoId}`
      this.showWebpageDialog = true
    },
    didCloseWebpage () {
      this.showWebpageDialog = false
      this.webpageURL = ''
    }
  }
}
</script>

<style scoped>
  .play-icon {
    position: absolute;
    top: 45%;
    left: 50%;
    transform:translate(-50%, -50%);
    pointer-events: none;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .tile {
    position: relative;
  }
  .tile-header-title {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 13pt;
  }
  .tile-headline {
    position: absolute;
    left: 10px;
    top: 40px;
    font-size: 24pt;
    font-weight: bold;
    line-height: 28pt;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  }
  .tile-carousel {
    border-radius: 10px 10px 0 0;
  }
  .tile-footer-panel {
    border-radius: 0 0 10px 10px;
    border: solid 1px #e8e8e8;
  }
  .caption {
    font-size: .83em !important;
  }
</style>
