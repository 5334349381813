import api from './API'

const URL = '/organisations'

export default {
  list: function () {
    return api.newRequest('get', URL)
  },
  view: function (id) {
    return api.newRequest('get', `${URL}/${id}`)
  },
  create: function (item) {
    return api.newRequest('post', URL, item)
  },
  update: function (item) {
    return api.newRequest('patch', `${URL}/${item.id}`, item)
  },
  delete: function (id) {
    return api.newRequest('delete', `${URL}/${id}`)
  },
  about: function (id) {
    return api.newRequest('get', `${URL}/${id}/about`)
  },
  contact: function (payload) {
    return api.newRequest('post', `${URL}/${payload.id}/enquiry`, payload)
  }
}
