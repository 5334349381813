import appAPI from '@/api/AppAPI'
import router from '@/router'

const state = {
  config: null,
  name: '',
  version: '',
  appLayout: '',
  serverVersion: '',
  appLogoURL: '',
  organisationId: '',
  devAssetServerURL: '',
  assetServerURL: '',
  welcomeBackgroundURL: '',
  welcomeLogoURL: '',
  welcomeMessages: '',
  welcomeTextColour: '',
  signupExtraFields: null,
  navHidden: false,
  carousel: null,
  gallery: null,
  tiles: []
}

const mutations = {
  setNavHidden (state, hidden) {
    state.navHidden = hidden
  },
  setApp (state, app) {
    state.name = app.name
    state.version = app.version
    state.appLayout = app.appLayout
    state.appLogoURL = app.appLogoURL
    state.carousel = app.carousel
    state.tiles = app.tiles
    state.organisationId = app.organisationId
    state.devAssetServerURL = app.devAssetServerURL
    state.assetServerURL = app.assetServerURL
    state.welcomeBackgroundURL = app.welcomeBackgroundURL
    state.welcomeLogoURL = app.welcomeLogoURL
    state.welcomeMessages = app.welcomeMessages
    state.welcomeTextColour = app.welcomeTextColour
    state.serverVersion = app.serverVersion
    state.signupExtraFields = app.signupExtraFields

    const isFVC = window.localStorage.getItem('type') === 'fvc'
    // If user is not signed in and we are not in FirstVue Custom, go to Welcome screen
    if (window.localStorage.getItem('jwt') == null &&
      !location.pathname.startsWith('/invitation/') &&
      !location.pathname.startsWith('/reset-password/') &&
      !location.pathname.startsWith('/terms') &&
      !location.pathname.startsWith('/privacy') &&
      !isFVC) {
      router.push('/welcome')
    }
  },
  setGallery (state, gallery) {
    state.gallery = gallery
  }
}

const getters = {
  assetServerURL (state, getters) {
    const env = getters.env
    return env === 'dev' ? `${state.devAssetServerURL}/${env}` : `${state.assetServerURL}/${env}`
  },
  buildVersion (state) {
    return window.localStorage.getItem('buildVersion')
  },
  env (state) {
    const env = window.localStorage.getItem('env')
    // if (env === null) {
    //   env = 'prod'
    // }
    return env
  },
  // FirstVue Agent app
  isFVA () {
    return window.localStorage.getItem('type') === 'fva'
  },
  // FirstVue Custom app
  isFVC () {
    return window.localStorage.getItem('type') === 'fvc'
  }
}

const actions = {
  view ({ commit }, appToken) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    return new Promise((resolve, reject) => {
      appAPI.view(appToken)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          commit('setApp', resp.data.app)
          resolve()
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
          reject(error)
        })
    })
  },
  hideNav ({ commit }, hidden) {
    commit('setNavHidden', hidden)
  },
  getGallery ({ commit }) {
    commit('common/setLoading', true, { root: true })
    commit('common/clearError', null, { root: true })
    return new Promise((resolve, reject) => {
      appAPI.getGallery()
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          commit('setGallery', resp.data.gallery)
          resolve()
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          console.log(error)
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
