import api from './API'
import axios from 'axios'

const HOST = process.env.VUE_APP_ANALYTICS_HOST
const API_URL = `${HOST}/v1`

const REPORTS_SESSIONS_URL = '/reports/sessions'
const REPORTS_IDENTITIES_URL = '/reports/identities'

// The below endpoints belongs to the api.firstvue.io and not the analytics apis
const APP_USERS_URL = '/app/users'

function handleResponse (response, resolve, reject) {
  if (response.unauthorized) {
    reject(response.error)
  } else if (response.status === 'error') {
    reject(response.error)
  } else {
    resolve(response)
  }
}

export default {
  API_URL: `${HOST}/v1`,
  newRequest (method, url, payload) {
    const fullUrl = API_URL + url
    const headers = {
      'Content-Type': 'application/json',
      'API-Token': window.localStorage.getItem('analyticsToken')
    }
    console.log(`AnalyticsReportsAPI :: newRequest : url ${fullUrl}`)
    console.log('AnalyticsReportsAPI :: newRequest : headers = ')
    console.log(headers)
    return new Promise(function (resolve, reject) {
      axios({
        method: method,
        url: fullUrl,
        data: payload,
        headers: headers
      }).then((resp) => {
        console.log(resp.data)
        console.log(resp.status)
        console.log(resp.statusText)
        console.log(resp.headers)
        console.log(resp.config)
        handleResponse(resp.data, resolve, reject)
      }).catch((error) => {
        console.log(error)
        const errorResponse = { code: 0, title: 'Unkown API error', message: 'Unkown API error' }
        if (error.response) {
          // if (error.response.status === 401) {
          //   // Sign out unauthorized tokens
          //   store.dispatch('auth/signout')
          //   // router.push('/login')
          // } else if (error.response.data && error.response.data.status === 'error') {
          //   errorResponse = error.response.data.error
          // }
        } else {
          errorResponse.title = error.message
          errorResponse.message = error.message
        }
        reject(errorResponse)
      })
    })
  },
  getSessionsReport: function () {
    return this.newRequest('post', REPORTS_SESSIONS_URL, null)
  },
  getAppUsers: function (ids) {
    return api.newRequest('post', APP_USERS_URL, ids)
  },
  getIdentityReport: function (identityId) {
    const url = `${REPORTS_IDENTITIES_URL}/${identityId}`
    return this.newRequest('post', url, null)
  }
}
