import api from './API'

const ACCOUNTS_URL = '/accounts'

export default {
  list: function () {
    return api.newRequest('get', ACCOUNTS_URL)
  },
  view: function (id) {
    const url = ACCOUNTS_URL + '/' + id
    return api.newRequest('get', url)
  },
  create: function (account) {
    console.log('creating account...')
    return api.newRequest('post', ACCOUNTS_URL, account)
  },
  update: function (account) {
    const url = ACCOUNTS_URL + '/' + account.id
    return api.newRequest('patch', url, account)
  },
  delete: function (id) {
    const url = ACCOUNTS_URL + '/' + id
    return api.newRequest('delete', url)
  }
}
