import organisationsAccountsAPI from '@/api/OrganisationAccountAPI'

const state = {
  items: [] // Accounts belonging to this organisation
}

const getters = {
  items (state) {
    return state.items.sort((itemA, itemB) => {
      return itemA.dateModified > itemB.dateModified
    })
  }
}

const actions = {
  list ({ commit }, organisationId) {
    commit('common/setLoading', true, { root: true })
    organisationsAccountsAPI.list(organisationId)
      .then(resp => {
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        commit('setItems', resp.data.accounts)
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  create ({ commit, getters }, { organisationId, payload }) {
    commit('common/setLoading', true, { root: true })
    return new Promise((resolve, reject) => {
      organisationsAccountsAPI.create(organisationId, payload)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          commit('common/clearError', null, { root: true })
          commit('addItem', resp.data.newAccount)
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  },
  update ({ commit }, { organisationId, accountId, payload }) {
    commit('common/setLoading', true, { root: true })
    return new Promise((resolve, reject) => {
      organisationsAccountsAPI.update(organisationId, accountId, payload)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          commit('setItem', resp.data.account)
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  },
  delete ({ commit }, { organisationId, accountId }) {
    organisationsAccountsAPI.delete(organisationId, accountId)
      .then(resp => {
        console.log(resp)
        commit('common/setLoading', false, { root: true })
        commit('delete', accountId)
      })
      .catch(error => {
        commit('common/setLoading', false, { root: true })
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  },
  reinvite ({ commit }, { organisationId, accountId }) {
    return new Promise((resolve, reject) => {
      organisationsAccountsAPI.reinvite(organisationId, accountId)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  }
}

const mutations = {
  setItems (state, items) {
    state.items = items
  },
  addItem (state, payload) {
    state.items.push(payload)
  },
  setItem (state, payload) {
    const item = state.items.find(item => {
      return item.id === payload.id
    })
    item.firstName = payload.firstName
    item.lastName = payload.lastName
    item.email = payload.email
    item.role = payload.role
    item.assignedProjects = payload.assignedProjects
    item.isActive = payload.isActive
  },
  delete (state, itemId) {
    state.items = state.items.filter(function (item) { return item.id !== itemId })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
