<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="interestsTableHeaders"
        :items="userInterestsTableData"
        class="elevation-1"
        hide-default-footer
        sort-by="count"
        :sort-desc="[true]"
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>User Interests</v-toolbar-title>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    interestsTableHeaders: [
      { text: 'Source', align: 'left', sortable: true, value: 'source' },
      { text: 'Count', align: 'left', sortable: true, value: 'count', width: '20' }
    ]
  }),
  computed: {
    ...mapState('analyticsReports', ['userInterestsTableData'])
  }
}
</script>
