<template>
  <div class="featured-item">
    <v-carousel
      cycle
      height="280"
      :show-arrows="false"
      class="rounded-lg"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(slide, i) in gallery.featured"
        :key="i"
        @click="selectItem(slide)"
      >
        <v-sheet height="100%">
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
            <v-img :src="getImageSrc(slide.backgroundURL)" height="300"></v-img>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-img class="play-icon" :src="require('../../assets/button-play.png')" width="80px"></v-img>
    <webpage-dialog
      :title="webpageTitle"
      :model="showWebpageDialog"
      :url="webpageURL"
      :fullscreen="webpageIsFullscreen"
      :didClose="didCloseWebpage">
    </webpage-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['gallery'],
  data: () => ({
    webpageURL: '',
    webpageTitle: '',
    webpageIsFullscreen: true,
    showWebpageDialog: false
  }),
  computed: {
    ...mapGetters('app', ['assetServerURL'])
  },
  methods: {
    getImageSrc (url) {
      return `${this.assetServerURL}${url}`
    },
    selectItem (item) {
      this.webpageURL = `/embedded-video.html?v=${item.videoId}`
      this.showWebpageDialog = true
    },
    didCloseWebpage () {
      this.showWebpageDialog = false
      this.webpageURL = ''
    }
  }
}
</script>

<style scoped>
.featured-item {
  position: relative;
}
.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
  pointer-events: none;
}
</style>
