import Vue from 'vue'
import Vuex from 'vuex'
import AppStore from './modules/AppStore'
import AuthStore from './modules/AuthStore'
import CommonStore from './modules/CommonStore'
import ProjectStore from './modules/ProjectStore'
import NotificationStore from './modules/NotificationStore'
import OrganisationStore from './modules/OrganisationStore'
import AccountStore from './modules/AccountStore'
import OrganisationAccountsStore from './modules/OrganisationAccountsStore'
import OrganisationProjectsStore from './modules/OrganisationProjectsStore'
import AnalyticsStore from './modules/AnalyticsStore'
import AnalyticsReportsStore from './modules/AnalyticsReportsStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app: AppStore,
    auth: AuthStore,
    common: CommonStore,
    projects: ProjectStore,
    organisations: OrganisationStore,
    accounts: AccountStore,
    notifications: NotificationStore,
    organisationAccounts: OrganisationAccountsStore,
    organisationProjects: OrganisationProjectsStore,
    analytics: AnalyticsStore,
    analyticsReports: AnalyticsReportsStore
  }
})
