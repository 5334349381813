<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Notifications</v-toolbar-title>
      <v-toolbar-items>
        <v-btn text color="primary" dark @click="dialog = !dialog">
          <v-icon dark>mdi-plus</v-icon> New
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-text-field
              v-model="editedItem.title"
              label="Title"
              counter="60"
              :rules="[rules.titleRequired, rules.titleCounter]">
            </v-text-field>
            <v-textarea
              v-model="editedItem.message"
              label="Message"
              counter="158"
              rows="3"
              :rules="[rules.messageRequired, rules.messageCounter]">
            </v-textarea>
            <datetime-picker
            :rules="[rules.messageRequired]"
            date-format="MM/dd/yyyy" label="Select Datetime" v-model="editedItem.dateScheduled"> </datetime-picker>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="close">Cancel</v-btn>
          <v-btn type="submit" class="primary" :disabled="loading" :loading="loading">Submit</v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        :loading="loading"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.name`]="props">
          <router-link :to="'/organisations/' + props.item.id+ '/accounts'">{{ props.item.name }}</router-link>
        </template>
        <template v-slot:[`item.title`]="props">
          <div><b>{{ props.item.title }}</b></div>
          <div>{{ props.item.message }}</div>
        </template>
        <template v-slot:[`item.dateScheduled`]="{item}">
          {{ item.dateScheduled | formatDate }}
        </template>
        <template v-slot:[`item.isPublished`]="{item}">
          <v-switch :loading="item.isPublishing" v-model="item.isPublished" @change="onPublishSwitchChange(item)"></v-switch>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <notification-edit-menu :notificationId="item.id"></notification-edit-menu>
        </template>
        <template v-slot:no-data>
          You have not added any organisations
        </template>
      </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formatISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export default {
  data: () => ({
    valid: true,
    dialog: false,
    menu: false,
    deleteDialog: false,
    itemToDelete: null,
    headers: [
      { text: 'Notification', align: 'left', value: 'title' },
      { text: 'Date Sent', align: 'left', value: 'dateSent' },
      // { text: 'Date Created', value: 'dateCreated', align: 'right', sortable: true },
      { text: 'Scheduled', value: 'dateScheduled', align: 'right', sortable: true },
      { text: 'Published', value: 'isPublished' },
      { text: 'Actions', align: 'centre', value: 'actions', sortable: false }
    ],
    editedIndex: -1,
    editedItem: { title: '', message: '', dateScheduled: new Date() },
    defaultItem: { title: '', message: '', dateScheduled: new Date() },
    rules: {
      titleRequired: v => !!v || 'Notification title is required.',
      messageRequired: v => !!v || 'Notification message is required.',
      scheduledRequired: v => !!v || 'Scheduled date is required.',
      titleCounter: v => {
        if (!v) return ''
        return v.length <= 60 || 'Max 60 characters'
      },
      messageCounter: v => {
        if (!v) return ''
        return v.length <= 158 || 'Max 158 characters'
      }
    }
  }),
  computed: {
    ...mapState('notifications', ['items']),
    ...mapState('common', ['error', 'loading']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Notification' : 'Edit Notification'
    }
  },
  created () {
    this.$store.dispatch('notifications/list')
    this.$store.dispatch('analytics/logScreen', { name: 'notification-list' })
  },
  methods: {
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.reset()
      }, 300)
    },
    onPublishSwitchChange (item) {
      console.log(item)
      item.isPublishing = true
      const payload = {
        id: item.id,
        published: item.isPublished
      }
      this.$store.dispatch('notifications/publish', payload)
        .then(resp => {
          item.isPublishing = false
        })
    },
    submit () {
      if (this.$refs.form.validate()) {
        const zoned = utcToZonedTime(this.editedItem.dateScheduled)
        const isoDate = formatISO(zoned)
        const payload = {
          title: this.editedItem.title,
          message: this.editedItem.message,
          dateScheduled: isoDate
        }
        if (this.editedIndex > -1) {
          this.$store.dispatch('notifications/update', payload)
            .then(resp => {
              this.close()
            })
        } else {
          this.$store.dispatch('notifications/create', payload)
            .then(resp => {
              this.close()
            })
        }
      }
    }
  }
}
</script>
