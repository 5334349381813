import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import HomeNew from '@/views/HomeDefaultLayout.vue'
import WelcomeCustom from '@/views/WelcomeCustom.vue'
// import Tours from '@/views/tours/TourList.vue'
// import TourView from '@/views/tours/TourView.vue'
// import Account from '@/views/Account.vue'
// import Settings from '@/views/Settings.vue'
// import Signout from '@/views/Signout.vue'

import FVCLogin from '@/views/auth/fvc/FVCLogin'
import FVCRegister from '@/views/auth/fvc/FVCRegister'
import Login from '@/views/auth/Login'
import Register from '@/views/auth/Register'
import RegistrationComplete from '@/views/auth/RegistrationComplete'
import ForgotPassword from '@/views/auth/ForgotPassword'
import NewPassword from '@/views/auth/NewPassword'
import ConfirmEmail from '@/views/auth/ConfirmEmail'
import AcceptInvitation from '@/views/auth/AcceptInvitation'
import Signout from '@/views/auth/Signout'
import AboutOrganisations from '@/views/organisations/About'
import ContactOrganisations from '@/views/organisations/Contact'
import OrganisationList from '@/views/organisations/OrganisationList'
import OrganisationAccounts from '@/views/organisations/OrganisationAccounts'
import Account from '@/views/accounts/Account'
import Startup from '@/views/Startup'

import NotificationList from '@/views/notifications/NotificationList'

import Insights from '@/views/insights/Insights'
import InsightsUsers from '@/views/insights/users/InsightsUsers'
import InsightsSessions from '@/views/insights/InsightsSessions'
import InsightsSummary from '@/views/insights/InsightsSummary'
import InsightsUserDetails from '@/views/insights/users/InsightsUserDetails'

import Gallery from '@/views/gallery/Gallery'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // noAuthForFVC: doesn't require authentication for FirstVue Custom builds
    { path: '/', name: 'home', component: Home, meta: { requiresAuth: true, noAuthForFVC: true } },
    { path: '/new', name: 'home new', component: HomeNew, meta: { requiresAuth: true, noAuthForFVC: true } },
    { path: '/startup', name: 'Startup', component: Startup },
    { path: '/welcome', name: 'welcome custom', component: WelcomeCustom },
    // { path: '/account', name: 'My Account', component: Account, meta: { requiresAuth: true } },
    { path: '/fvclogin', name: 'FVC Login', component: FVCLogin },
    { path: '/fvcregister', name: 'FVC Register', component: FVCRegister },
    { path: '/login', name: 'Login', component: Login },
    { path: '/register', name: 'Register', component: Register },
    { path: '/registration/complete', name: 'Register Confirmation', component: RegistrationComplete },
    { path: '/forgot-password', name: 'Forgot Password', component: ForgotPassword },
    { path: '/reset-password/:hash', name: 'New Password', component: NewPassword },
    { path: '/email-confirmation/:hash', name: 'Confirm Email', component: ConfirmEmail },
    { path: '/invitation/:hash', name: 'Accept Invitation', component: AcceptInvitation },
    { path: '/organisations', name: 'organisations', component: OrganisationList, meta: { requiresAuth: true } },
    { path: '/organisations/:id/accounts', name: 'agency', component: OrganisationAccounts, meta: { requiresAuth: true } },
    { path: '/signout', name: 'Sign out', component: Signout },
    { path: '/about/:id', name: 'About', component: AboutOrganisations, meta: { requiresAuth: true, noAuthForFVC: true } },
    { path: '/contact/:id', name: 'Contact', component: ContactOrganisations, meta: { requiresAuth: true, noAuthForFVC: true } },
    { path: '/account/', name: 'My Account', component: Account, meta: { requiresAuth: true } },
    {
      path: '/insights/',
      name: 'Insights',
      component: Insights,
      meta: { requiresAuth: true },
      children: [
        { path: '', component: InsightsSummary, meta: { requiresAuth: true } },
        { path: 'users/:tab', component: InsightsUsers, meta: { requiresAuth: true } },
        { path: 'users/accounts/:id', component: InsightsUserDetails, meta: { requiresAuth: true } }
      ]
    },
    { path: '/gallery', name: 'Gallery', component: Gallery },
    // { path: '/insights/users', name: 'Insights Users', component: InsightsUsers, meta: { requiresAuth: true } },
    // { path: '/insights/users/:tab', name: 'Insights User Details', component: InsightsUsers, meta: { requiresAuth: true } },
    // { path: '/insights/users/:id', name: 'Insights User Details', component: InsightsUserDetails, meta: { requiresAuth: true } },
    { path: '/insights/sessions', name: 'Insights Sessions', component: InsightsSessions, meta: { requiresAuth: true } },
    { path: '/notifications', name: 'Notifications', component: NotificationList, meta: { requiresAuth: true } }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // {
    //   path: '/privacy',
    //   name: 'privacy',
    //   component: () => import('@/views/static/Privacy.vue')
    // }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.matched.some(record => record.meta.noAuthForFVC)) { // && localStorage.getItem('type') === 'fvc') {
      next()
    } else if (localStorage.getItem('jwt') == null) {
      let query = {}
      // No need to set 'nextUrl' if there is none
      if (to.fullPath !== '/') {
        query = { nextUrl: to.fullPath }
      }
      next({
        path: '/startup',
        query: query
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
