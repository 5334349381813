<template>
  <v-container fluid>
    <v-app-bar app elevate-on-scroll color="white">
      <v-btn icon to="/">
        <v-icon dark>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Registration Complete</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="6" md="4" class="text-center">
        <div class="display-1">Almost there!</div>
        <div class="mt-4">Thank you for registering to FirstVue!</div>
        <div
          class="mt-4 headline"
        >Please check your inbox and your SPAM folder for a confirmation email. Click the link in the email to confirm your email address and start using the app.</div>
        <v-icon large class="mt-4">mdi-email</v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  created () {
    this.$store.dispatch('analytics/logScreen', { name: 'auth-registration-complete' })
  }
}
</script>
