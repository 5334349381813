<template>
<v-container>
  <div v-if="!gallery">
    loading...
  </div>
  <div v-else>
    <v-row v-if="gallery">
      <v-col cols="12" xl="6">
        <gallery-featured-carousel :gallery="gallery"></gallery-featured-carousel>
      </v-col>
    </v-row>
    <v-row v-for="section in gallery.sections" :key="section.id">
      <v-col cols="12" v-if="section.title">
        <div class="overline">{{section.title}}</div>
      </v-col>
      <template v-if="section.showCarousels">
        <gallery-carousel-item v-for="item in section.items" :key="item.id" :item="item"></gallery-carousel-item>
      </template>
      <template v-else>
        <gallery-default-item v-for="item in section.items" :key="item.id" :item="item"></gallery-default-item>
      </template>
    </v-row>
  </div>
</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GalleryFeaturedCarousel from './GalleryFeaturedCarousel'
import GalleryCarouselItem from './GalleryCarouselItem'
import GalleryDefaultItem from './GalleryDefaultItem'

export default {
  components: {
    GalleryFeaturedCarousel,
    GalleryCarouselItem,
    GalleryDefaultItem
  },
  computed: {
    projects () {
      const allProjects = this.$store.getters['projects/items']
      return allProjects.filter(function (item) { return item.type === 'firstvue' })
    },
    ...mapState('common', ['loading']),
    ...mapGetters('app', ['isFVC', 'assetServerURL']),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapState('app', ['gallery', 'tiles'])
  },
  data: () => ({
    item: null,
    showWebpageDialog: false,
    showTimelapseDialog: false,
    timelapseSrcURL: '',
    webpageURL: '',
    webpageTitle: '',
    webpageIsFullscreen: true,
    activityDialog: false,
    activityDialogMessage: 'Let there be light...',
    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4'
    ],
    slides: [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth'
    ]
  }),
  created () {
    this.$store.dispatch('app/getGallery')
      .then(resp => {

      })
    this.$store.dispatch('analytics/logScreen', { name: 'gallery' })
  },
  methods: {
    getPrimaryButtonLabel (project) {
      if (project.type === 'webpage') {
        if (project.config.primaryButtonLabel) {
          return project.config.primaryButtonLabel
        } else {
          return 'Visit'
        }
      } else {
        return 'Explore'
      }
    },
    getPrimaryButtonIcon (project) {
      let icon = null
      if (project.type === 'webpage') {
        if (project.config.primaryButtonIcon) {
          icon = project.config.primaryButtonIcon
        } else {
          icon = 'mdi-link'
        }
      }
      return icon
    },
    getBannerSrc (project) {
      let bannerURL = ''
      if (project.config.projectBannerURL) {
        bannerURL = project.config.projectBannerURL
      } else if (project.org.config.projectBannerURL) {
        bannerURL = project.org.config.projectBannerURL
      }
      return `${this.assetServerURL}${bannerURL}`
    },
    getImageSrc (url) {
      return `${this.assetServerURL}${url}`
    },
    explore (project) {
      if (project.type !== 'webpage') {
        if (this.isAuthenticated) {
          this.activityDialog = true
          this.$store.dispatch('analytics/logEvent', { name: 'select-project', data: { projectId: project.id, projectName: project.name } })

          const platform = window.localStorage.getItem('platform')
          if (platform === 'osx' || platform === 'win') {
            const jsonString = JSON.stringify({
              projectId: project.id
            })
            window.fvLoadSceneForProjectId(jsonString)
          } else {
            window.location.href = `uniwebview://firstvue-scene?id=${project.id}`
          }
        } else {
          if (this.isFVC) {
            this.$router.push('/fvclogin')
          }
        }
      } else {
        this.showWebpageDialog = true
        this.webpageTitle = project.name
        this.webpageURL = project.config.webpageURL
        this.webpageIsFullscreen = project.config.webpageIsFullscreen
        this.$store.dispatch('analytics/logEvent', { name: 'select-webpage', data: { projectId: project.id, projectName: project.name, url: project.config.webpageURL, fullscreen: project.config.webpageIsFullscreen } })
      }
    },
    openTile (tile) {
      this.showWebpageDialog = true
      this.webpageTitle = tile.title
      this.webpageURL = tile.webpageURL
      this.webpageIsFullscreen = true
      this.$store.dispatch('analytics/logEvent', { name: 'select-tile', data: { tileId: tile.id, tileTile: tile.title, url: tile.webpageURL, fullscreen: true } })
    },
    viewTimelapse (project) {
      this.showTimelapseDialog = true
      this.timelapseSrcURL = project.config.timelapseURL
      this.$store.dispatch('analytics/logEvent', { name: 'select-project-timelapse', data: { projectId: project.id, projectName: project.name } })
    },
    didDismissTimelapseDialog () {
      this.showTimelapseDialog = false
    },
    didCloseWebpage () {
      this.showWebpageDialog = false
    },
    doSomething () {
      alert('test')
    },
    getTileFooterTitleStyle (item) {
      const colour = item.footerTitleColour ?? 'black'
      return `color: ${colour}`
    },
    getTileFooterSubtitleStyle (item) {
      const colour = item.footerSubtitleColour ?? '#757575'
      return `color: ${colour}`
    },
    getTileFooterStyle (item) {
      const colour = item.footerBackgroundColour ?? '#f7f7f7'
      return `background-color: ${colour}`
    }
  }
}
</script>
