import api from './API'

const ENDPOINT = '/apps'

// For APIs related to a single app
const APP_ENDPOINT = '/app'

export default {
  view: function (appToken) {
    return api.newRequest('get', `${ENDPOINT}/${appToken}`)
  },
  getGallery: function () {
    return api.newRequest('get', `${APP_ENDPOINT}/gallery`)
  }
}
