<template>
  <v-app>
    <!-- <app-global-error v-if="globalError"></app-global-error> -->
    <app-alert-dialog :model="hasGlobalError" :didDismiss="didDismiss" :title="globalErrorTitle" :message="globalErrorMessage" ></app-alert-dialog>
    <div>
      <layout-unauthenticated v-if="!isAuthenticated && isFVA"></layout-unauthenticated>
      <layout-authenticated-firstvue-agent v-else-if="isAuthenticated && isFVA"></layout-authenticated-firstvue-agent>
      <layout-authenticated-firstvue-custom v-else-if="isFVC"></layout-authenticated-firstvue-custom>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import LayoutUnauthenticated from '@/views/layouts/Unauthenticated'
import LayoutAuthenticatedFirstvueAgent from '@/views/layouts/AuthenticatedFirstVueAgent'
import LayoutAuthenticatedFirstvueCustom from '@/views/layouts/AuthenticatedFirstVueCustom'
// import LayoutAuthenticatedFirstvueCustomLayout from '@/views/layouts/AuthenticatedFirstVueCustomLayout'

export default {
  data: () => ({
    isFVA: false,
    isFVC: false
  }),
  components: {
    LayoutUnauthenticated,
    LayoutAuthenticatedFirstvueAgent,
    LayoutAuthenticatedFirstvueCustom
    // LayoutAuthenticatedFirstvueCustomLayout
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('common', ['globalErrorTitle', 'globalErrorMessage', 'hasGlobalError'])
  },
  created () {
    console.log('App.vue : Created')
    const that = this
    // If we are running app in browser during development
    // then we manualy simulate calling the startApp
    const embedded = window.localStorage.getItem('embedded')
    console.log(`App.vue :: startApp : embedded = ${embedded}`)
    if (embedded) {
      window.actions = window.actions || {}
      window.actions.startApp = function (json) {
        const data = JSON.parse(json)
        console.log(data)
        // Store data coming from Unity
        window.localStorage.setItem('platform', data.platform)
        window.localStorage.setItem('token', data.token)
        window.localStorage.setItem('buildVersion', data.buildVersion)
        window.localStorage.setItem('env', data.env)
        window.localStorage.setItem('type', data.type)
        window.localStorage.setItem('analyticsToken', data.analyticsToken)
        window.localStorage.setItem('analyticsSessionId', data.analyticsSessionId)
        that.isFVA = data.type === 'fva'
        that.isFVC = data.type === 'fvc'

        // Get app configs from the server
        that.$store.dispatch('app/view', data.token)
      }
    } else {
      let env = 'prod'
      // Default values to FirstVue Agent in prod
      let type = 'fva'
      let appToken = '20000000-acfa-400a-987d-6b34a71a9f75'
      this.isFVA = true
      this.isFVC = false

      if (!location.origin.includes('firstvue.io')) {
        env = 'dev'
        type = 'fvc'
        this.isFVA = false
        this.isFVC = true
        // Test Hyecorp custom app
        appToken = '7c06645e-86ba-4841-b2bb-c63047ef181c'
        window.localStorage.setItem('analyticsToken', '8bebf13a-3b9a-44bf-958f-089eeb031e78')

        // Test Hyegrove
        // appToken = 'bff67ab4-7ce3-47d6-9968-8a80fe265718'
        // window.localStorage.setItem('analyticsToken', null)
      }

      // set env to 'prod' to test on production
      window.localStorage.setItem('token', appToken)
      window.localStorage.setItem('env', env)
      window.localStorage.setItem('type', type)

      that.$store.dispatch('app/view', appToken)
    }
  },
  methods: {
    didDismiss () {
      this.$store.dispatch('common/clearError')
    }
  }
}
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
