import notificationAPI from '@/api/NotificationAPI'

const state = {
  items: [],
  loadedItem: {}
}

const getters = {
  items (state) {
    return state.items.sort()
  },
  loadedItem (state) {
    return state.loadedItem
  }
}

const actions = {
  list ({ commit }) {
    commit('common/setLoading', true, { root: true })
    notificationAPI.list()
      .then(resp => {
        console.log(resp)
        commit('setItems', resp.data.notifications)
        commit('common/setLoading', false, { root: true })
      })
      .catch(error => {
        commit('common/setError', error, { root: true })
        commit('common/setLoading', false, { root: true })
        console.log(error)
      })
  },
  // view ({ commit, dispatch }, id) {
  //   commit('common/setLoading', true, { root: true })
  //   notificationAPI.view(id)
  //     .then(resp => {
  //       console.log(resp)
  //       commit('common/setLoading', false, { root: true })
  //       commit('setLoadedItem', resp.data.tour)
  //       dispatch('locations/setLocations', resp.data.tour.locations, { root: true })
  //     })
  //     .catch(error => {
  //       commit('common/setLoading', false, { root: true })
  //       commit('common/setError', error, { root: true })
  //       console.log(error)
  //     })
  // },
  create ({ commit, getters }, payload) {
    commit('common/setLoading', true, { root: true })
    return new Promise((resolve, reject) => {
      notificationAPI.create(payload)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          commit('common/clearError', null, { root: true })
          commit('addItem', resp.data.notification)
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  },
  publish ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      notificationAPI.publish(payload)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  },
  update ({ commit }, payload) {
    commit('common/setLoading', true, { root: true })
    return new Promise((resolve, reject) => {
      notificationAPI.update(payload)
        .then(resp => {
          commit('common/setLoading', false, { root: true })
          commit('setItem', resp.data.notification)
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  },
  delete ({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      notificationAPI.delete(itemId)
        .then(resp => {
          console.log(resp)
          commit('common/setLoading', false, { root: true })
          commit('delete', itemId)
          resolve(resp)
        })
        .catch(error => {
          commit('common/setLoading', false, { root: true })
          commit('common/setError', error, { root: true })
          reject(error)
          console.log(error)
        })
    })
  }
  // duplicate ({ commit, getters }, payload) {
  //   commit('common/setLoading', true, { root: true })
  //   console.log(payload)
  //   return new Promise((resolve, reject) => {
  //     const newUcord = { name: payload.name }
  //     ucordsApi.duplicateUcord(payload.id, newUcord)
  //       .then(resp => {
  //         commit('common/setLoading', false, { root: true })
  //         commit('common/clearError', null, { root: true })
  //         commit('addItem', resp.data)
  //         resolve(resp)
  //       })
  //       .catch(error => {
  //         commit('common/setLoading', false, { root: true })
  //         commit('common/setError', error, { root: true })
  //         reject(error)
  //         console.log(error)
  //       })
  //   })
  // }
}

const mutations = {
  setItems (state, items) {
    state.items = items
  },
  addItem (state, payload) {
    state.items.push(payload)
  },
  setLoadedItem (state, payload) {
    payload.infoExpanded = false
    state.loadedItem = payload
  },
  setItem (state, payload) {
    const item = state.items.find(item => {
      return item.id === payload.id
    })
    item.name = payload.name
    item.dateModified = payload.dateModified
    item.isLocked = payload.isLocked
  },
  delete (state, itemId) {
    state.items = state.items.filter(function (item) { return item.id !== itemId })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
