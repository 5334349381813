<template>
  <v-dialog width="550px" persistent v-model="dialogVisible">
      <v-card>
        <v-card-title primary-title>
          <div class="headline">Change Password</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="password"
                    :rules="[rules.required, rules.min]"
                    type="password"
                    name="password"
                    label="Type Password"
                    ref="password"
                    hint="At least 8 characters"
                    counter
                    required>
                  </v-text-field>
                  <v-text-field
                    v-model="passwordConfirmation"
                    :rules="[rules.required, rules.match]"
                    name="passwordConfirmation"
                    label="Retype Password"
                    type="password"
                    required>
                  </v-text-field>
                </v-form>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dismiss">Cancel</v-btn>
              <v-btn @click="submit" class="primary" :disabled="loading" :loading="loading">Submit</v-btn>
            </v-card-actions>
          </v-container>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['id', 'show', 'onDismiss', 'onSubmit'],
  data () {
    return {
      dialogVisible: false,
      valid: true,
      password: '',
      passwordConfirmation: '',
      rules: {
        required: v => !!v || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        match: v => v === this.password || 'Passwords don\'t match'
      }
    }
  },
  watch: {
    show (val) {
      this.dialogVisible = val
    }
  },
  computed: {
    comparePasswords () {
      return this.password !== this.confirmPassword ? 'Passwords do not match' : true
    },
    ...mapState('common', ['error', 'loading'])
  },
  methods: {
    dismiss () {
      this.password = ''
      this.passwordConfirmation = ''
      this.$store.dispatch('common/clearError')
      this.onDismiss()
    },
    submit () {
      if (this.$refs.form.validate()) {
        this.onSubmit(this.password)
      }
    }
  }
}
</script>
