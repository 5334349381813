<template>
  <v-container fluid>
    <v-row justify="center" class="text-center">
      <v-col cols="12">
        <div class="title mt-2">Contact Us</div>
      </v-col>
      <v-col v-if="didSubmit" cols="12" class="text-center">
        <div class="display-1">Enquiry Sent!</div>
        <div class="mt-4 headline">Thank you for your enquiry. One of our team members will get in touch with you soon.</div>
        <v-icon large class="mt-4">mdi-check-decagram</v-icon>
      </v-col>
      <v-col v-else cols="12" sm="6" md="4" class="pt-0">
        <v-card elevation="0" outlined color="transparent">
          <v-card-text>
            <v-form id="contact-form" ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="5" class="pr-2">
                  <v-text-field
                      v-model="firstName"
                      :rules="[rules.required]"
                      label="First name *"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="7">
                  <v-text-field
                      v-model="lastName"
                      :rules="[rules.required]"
                      label="Last name *"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="email"
                      :rules="[rules.required, rules.emailValid]"
                      label="E-mail *"
                      required
                      type="email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="phone"
                      :rules="[rules.required]"
                      label="Phone *"
                      required
                      type="phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="userType"
                    :items="userTypeItems"
                    :rules="[rules.required]"
                    label="I am a..."
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="userInterests"
                    :items="userInterestsItems"
                    :rules="[rules.required]"
                    attach
                    chips
                    required
                    label="I'm interested in..."
                    multiple
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="message"
                    auto-grow
                    rows="1"
                    label="Additional Comments"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="error" align="center" justify="center">
                <v-col cols="12">
                  <app-alert :title="error.title" :text="error.message"></app-alert>
                </v-col>
              </v-row>
            </v-form>
            <v-btn
              color="primary"
              @click="submit"
              :disabled="loading"
              :loading="loading"
              form="contact-form"
              class="mt-5"
              >
              submit
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  data: () => ({
    didSubmit: false,
    valid: true,
    rules: {
      required: v => !!v || 'Required.',
      emailValid: v => /.+@.+/.test(v) || 'E-mail must be valid'
    },
    userTypeItems: [
      'First Home Buyer', 'Upsizing Onwer Occupier', 'Downsizing Owner Occupier', 'Investor', 'Other'
    ],
    userInterestsItems: [
      'Studio', '1 Bedroom', '1 + Study/Multi-purpose room', '2 Bedrooms', '2 + Study/Multi-purpose room', '3 Bedrooms', '4 Bedrooms'
    ],
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    userType: '',
    userInterests: null,
    message: ''
  }),
  computed: {
    propertyDeveloperName () {
      return this.propertyDeveloper.name || ''
    },
    getImageSrc (project) {
      return this.config.aboutImageURL ? `${this.assetServerURL}${this.config.aboutImageURL}` : ''
    },
    ...mapState('organisations', {
      propertyDeveloper: 'loadedItem'
    }),
    ...mapState('common', ['error', 'loading']),
    ...mapState('auth', ['user']),
    ...mapGetters('app', ['isFVA'])
  },
  created () {
    this.$store.dispatch('analytics/logScreen', { name: 'organisation-contact', data: { organisationName: this.propertyDeveloperName } })
    this.firstName = this.user.firstName
    this.lastName = this.user.lastName
    this.email = this.user.email
    this.phone = this.user.phone
  },
  watch: {
    user (value) {
      if (value) {
        this.firstName = value.firstName
        this.lastName = value.lastName
        this.email = value.email
        this.phone = value.phone
      }
    }
  },
  methods: {
    submit () {
      this.clearError()
      const that = this
      if (this.$refs.form.validate()) {
        this.$store.dispatch('organisations/contact', {
          id: this.$route.params.id, // Organisation Id
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          userType: this.userType,
          userInterests: this.userInterests,
          message: this.message
        }).then(resp => {
          that.didSubmit = true
        }).catch(error => {
          console.log(error)
          that.didSubmit = false
        })
        this.$store.dispatch('analytics/logEvent', { name: 'submit-organisation-enquiry', data: { organisationName: this.propertyDeveloperName } })
      }
    },
    clearError () {
      this.$store.dispatch('common/clearError', null, { root: true })
    }
  }
}
</script>
