<template>
  <div>
    <app-alert-dialog :model="showSuccessDialog" :didDismiss="didDismissDialog" :title="successTitle" :message="successMessage"></app-alert-dialog>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn text fab v-on="on" small>
            <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="showResetPasswordDialog()">
          <v-list-item-title>Change password</v-list-item-title>
        </v-list-item>
        <v-list-item @click="deactivateConfirmation = true" v-if="canDeactivate">
          <v-list-item-title>Deactivate</v-list-item-title>
        </v-list-item>
        <v-list-item @click="activateAccount()" v-if="canActivate">
          <v-list-item-title>Reactivate</v-list-item-title>
        </v-list-item>
        <v-list-item @click="deleteConfirmation = true" v-if="userIsNotMe">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
        <v-list-item v-else @click="deleteMyAccountConfirmation = true">
          <v-list-item-title>Delete My Account</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="deleteConfirmation" max-width="290">
      <v-card>
        <v-card-title class="headline">Delete this user?</v-card-title>
        <v-card-text>Are you sure you would like to delete this user? They will no longer be able to access the system.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="deleteConfirmation = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click.native="deleteAccount()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteMyAccountConfirmation" max-width="500">
      <v-card>
        <v-card-title class="headline">Delete Account?</v-card-title>
        <v-card-text>Are you sure you would like to delete your account? All your data will be permanently removed and you will no longer be able to access the app.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="deleteConfirmation = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click.native="deleteAccount()">Delete My Account</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deactivateConfirmation" max-width="290">
      <v-card>
        <v-card-title class="headline">Deactivate user ?</v-card-title>
        <v-card-text>Deactivated users will not longer be able to access the system. You can reactive them later.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="deactivateConfirmation = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click.native="deactivateAccount()">Deactivate</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-edit-password-dialog :id="account.id" :show="editPassword" :onDismiss="dismissEditPassword" :onSubmit="onSubmitPassword"></app-edit-password-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: ['account'],
  data () {
    return {
      successTitle: '',
      successMessage: '',
      showSuccessDialog: false,
      editPassword: false,
      deleteConfirmation: false,
      deactivateConfirmation: false,
      deleteMyAccountConfirmation: false
    }
  },
  computed: {
    userIsNotMe () {
      return this.account.id !== this.user.id
    },
    canDeactivate () {
      return this.userIsNotMe && this.account.isActive
    },
    canActivate () {
      return this.userIsNotMe && !this.account.isActive
    },
    ...mapState('auth', ['user'])
  },
  methods: {
    dismissEditPassword () {
      this.editPassword = false
    },
    async deleteAccount () {
      this.deleteConfirmation = false
      await this.$store.dispatch('accounts/delete', this.account.id)
      if (this.userIsNotMe) {
        this.$router.push('/accounts')
      } else {
        this.$router.push('/signout')
      }
    },
    deactivateAccount () {
      this.deactivateConfirmation = false
      this.$store.dispatch('accounts/update', {
        id: this.account.id,
        isActive: false
      }).then(resp => {
        this.successTitle = 'Account Deactivated'
        this.successMessage = 'Account has been deactivated successfully. This user will no longer be able to log in.'
        this.showSuccessDialog = true
      })
    },
    activateAccount () {
      this.deactivateConfirmation = false
      this.$store.dispatch('accounts/update', {
        id: this.account.id,
        isActive: true
      }).then(resp => {
        this.successTitle = 'Account Reactivated'
        this.successMessage = 'Account has been re-activated successfully. This user can now log in.'
        this.showSuccessDialog = true
      })
    },
    showResetPasswordDialog () {
      this.editPassword = true
    },
    onSubmitPassword (newPassword) {
      this.$store.dispatch('accounts/update', {
        id: this.account.id,
        password: newPassword
      })
    },
    didDismissDialog () {
      this.showSuccessDialog = false
    }
  }
}
</script>
