<template>
  <v-container fluid>
    <!-- <v-app-bar app elevate-on-scroll color="white">
      <v-btn icon @click="$router.go(-1)">
        <v-icon dark>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title>Login</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar> -->
    <v-row justify="center">
      <v-col cols="12" sm="6" md="4">
        <div class="text-center">Please log in to continue</div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="4">
        <v-card elevation="0" outlined color="transparent">
          <v-card-text class="pt-0 pb-0">
            <v-card class="mb-4" v-if="error">
              <v-card-text>
                <p class="mb-0 red--text"><b>{{error.title}}:</b> {{error.message}}</p>
              </v-card-text>
            </v-card>
            <v-form id="login-form" ref="form" v-model="valid" lazy-validation @submit.prevent="onSubmit">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email"
                prepend-icon="mdi-email"
                clearable
                type="email"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                prepend-icon="mdi-lock"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text small color="primary" to="forgot-password">Forgot password?</v-btn>
            <v-spacer></v-spacer>
            <v-btn class="pl-5 pr-5" type="submit" :disabled="loading" :loading="loading" color="primary" form="login-form" rounded>Sign in</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="4">
        <v-divider class="mb-2"></v-divider>
        <div class="text-center mb-2">Don't have a <b>FirstVue&trade; Account</b>?</div>
        <div class="text-center"><v-btn color="primary" outlined rounded :to="'/fvcregister'">Register</v-btn></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    valid: true,
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    password: '',
    passwordRules: [
      v => !!v || 'Password is required'
    ]
  }),
  created () {
    // this.$store.dispatch('app/hideNav', true)
    this.$store.dispatch('common/clearError', null, { root: true })
    this.$store.dispatch('analytics/logScreen', { name: 'auth-login' })
  },
  computed: {
    ...mapState('common', ['error', 'loading'])
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('auth/signin', { email: this.email, password: this.password })
      }
    }
  }
}
</script>
