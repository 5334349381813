import organisationProjectsAPI from '@/api/OrganisationProjectsAPI'

const state = {
  projects: [], // Projects belonging to this organisation,
  isLoading: false
}

const getters = {
  projects (state) {
    return state.projects.sort((itemA, itemB) => {
      return itemA.dateModified > itemB.dateModified
    })
  }
}

const actions = {
  list ({ commit }, organisationId) {
    organisationProjectsAPI.list(organisationId)
      .then(resp => {
        console.log(resp)
        commit('setLoading', false)
        commit('setProjects', resp.data.projects)
      })
      .catch(error => {
        commit('setLoading', false)
        commit('common/setError', error, { root: true })
        console.log(error)
      })
  }
//   ,
//   create ({ commit, getters }, { organisationId, payload }) {
//     commit('common/setLoading', true, { root: true })
//     return new Promise((resolve, reject) => {
//       organisationsAccountsAPI.create(organisationId, payload)
//         .then(resp => {
//           commit('common/setLoading', false, { root: true })
//           commit('common/clearError', null, { root: true })
//           commit('addItem', resp.data.newAccount)
//           resolve(resp)
//         })
//         .catch(error => {
//           commit('common/setLoading', false, { root: true })
//           commit('common/setError', error, { root: true })
//           reject(error)
//           console.log(error)
//         })
//     })
//   },
//   update ({ commit }, { organisationId, accountId, payload }) {
//     commit('common/setLoading', true, { root: true })
//     return new Promise((resolve, reject) => {
//       organisationsAccountsAPI.update(organisationId, accountId, payload)
//         .then(resp => {
//           commit('common/setLoading', false, { root: true })
//           commit('setItem', resp.data.account)
//           resolve(resp)
//         })
//         .catch(error => {
//           commit('common/setLoading', false, { root: true })
//           commit('common/setError', error, { root: true })
//           reject(error)
//           console.log(error)
//         })
//     })
//   },
//   delete ({ commit }, { organisationId, accountId }) {
//     organisationsAccountsAPI.delete(organisationId, accountId)
//       .then(resp => {
//         console.log(resp)
//         commit('common/setLoading', false, { root: true })
//         commit('delete', accountId)
//       })
//       .catch(error => {
//         commit('common/setLoading', false, { root: true })
//         commit('common/setError', error, { root: true })
//         console.log(error)
//       })
//   },
//   reinvite ({ commit }, { organisationId, accountId }) {
//     return new Promise((resolve, reject) => {
//       organisationsAccountsAPI.reinvite(organisationId, accountId)
//         .then(resp => {
//           resolve(resp)
//         })
//         .catch(error => {
//           commit('common/setError', error, { root: true })
//           reject(error)
//           console.log(error)
//         })
//     })
//   }
}

const mutations = {
  setLoading (state, payload) {
    state.loading = payload
  },
  setProjects (state, projects) {
    state.projects = projects
  },
  addProject (state, payload) {
    state.projects.push(payload)
  },
  setProject (state, payload) {
    const project = state.projects.find(project => {
      return project.id === payload.id
    })
    project.firstName = payload.firstName
    project.lastName = payload.lastName
    project.email = payload.email
    project.role = payload.role
  },
  delete (state, projectId) {
    state.projects = state.projects.filter(function (project) { return project.id !== projectId })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
