<template>
  <v-dialog v-model="dialogVisible" max-width="600">
    <v-card>
      <v-card-title class="headline">{{title}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        {{message}}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="cancel">
          Cancel
        </v-btn>
        <v-btn class="primary" @click.native="submit">{{actionTitle}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    model: Boolean,
    title: {
      type: String,
      default: 'Title'
    },
    message: String,
    actionTitle: String,
    didCancel: Function,
    didSubmit: Function
  },
  data: () => ({
    dialogVisible: false
  }),
  watch: {
    model (val) {
      this.dialogVisible = val
    }
  },
  methods: {
    cancel () {
      this.$props.didCancel()
    },
    submit () {
      this.$props.didSubmit()
    }
  }
}
</script>
