import analyticsReportsAPI from '@/api/AnalyticsReportsAPI'

const state = {
  usersReport: [],
  users: [],
  userCount: 0,
  userId: null,
  identities: [],
  sessionsByDate: [],
  sessionsByUser: [],
  identitySessions: [],
  identitySessionsChartData: [],
  usersLastSeen: [],
  userSourcesTableData: [],
  userInterestsTableData: []
}

const getters = {
}

const actions = {
  getSessionsReport ({ commit }) {
    return new Promise((resolve, reject) => {
      analyticsReportsAPI.getSessionsReport()
        .then(resp => {
          console.log(resp)
          commit('setUsersReport', resp.data.usersReport)
          commit('setSessions', resp.data)
          resolve(resp)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  getAppUsers ({ commit }, ids) {
    return new Promise((resolve, reject) => {
      analyticsReportsAPI.getAppUsers(ids)
        .then(resp => {
          console.log(resp)
          commit('setUsers', resp.data)
          resolve(resp)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  getIdentityReport ({ commit }, id) {
    // Find and keep reference to the userId associated with this identityId
    const identity = state.identities.find(identity => {
      return identity.id === id
    })
    commit('setUserId', identity.identity)

    return new Promise((resolve, reject) => {
      analyticsReportsAPI.getIdentityReport(id)
        .then(resp => {
          console.log(resp)
          // commit('setUsersReport', resp.data.usersReport)
          commit('setIdentityReport', resp.data)
          resolve(resp)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
}
const mutations = {
  setSessions (state, data) {
    state.sessionsByDate = data.sessionsByDate
    state.sessionsByUser = data.sessionsByUser
    state.identities = data.identities
    state.usersLastSeen = data.usersLastSeen
  },
  setIdentityReport (state, data) {
    state.identitySessions = data.sessions
    state.identitySessionsChartData = data.sessionsChart
  },
  setUsersReport (state, usersReport) {
    state.usersReport = usersReport

    let total = 0
    usersReport.forEach(element => {
      total += element[1]
    })
    state.userCount = total
  },
  setUsers (state, data) {
    state.users = data.users

    state.userSourcesTableData = []
    Object.keys(data.sourcesReport).forEach(key => {
      state.userSourcesTableData.push({
        source: key,
        count: data.sourcesReport[key]
      })
    })

    state.userInterestsTableData = []
    Object.keys(data.interestsReport).forEach(key => {
      state.userInterestsTableData.push({
        source: key,
        count: data.interestsReport[key]
      })
    })
    // We get Sessions and set it to state before getting and setting Users
    // Now that we have all users we can loop through the sessions report
    // and set each user's analytics
    state.users.forEach(user => {
      state.sessionsByUser.forEach(session => {
        if (session[0] === user.id) {
          user.sessionCount = session[1]
        }
      })
      for (const lastSeenId in state.usersLastSeen) {
        if (user.id === lastSeenId) {
          user.lastSession = state.usersLastSeen[lastSeenId]
        }
      }
      state.identities.forEach(identity => {
        // Keeping track of the Analytics identity id
        if (user.id === identity.identity) {
          user.identityId = identity.id
        }
      })
    })
  },
  setUserId (state, userId) {
    state.userId = userId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
