<template>
    <div>
      <!-- <v-alert
        :value="!organisation.isApproved && !loading"
        type="error"
        icon="new_releases"
        outlined
      >
        This organisation has not yet been approved. Click 'Approve' to allow its users to login
        <v-spacer></v-spacer>
        <v-btn color="success" @click="approve">Approve</v-btn>
      </v-alert> -->
      <app-confirmation-dialog
        :model="showConfirmationDialog"
        :title="confirmationDialogTitle"
        :message="confirmationDialogMessage"
        :actionTitle="confirmationDialogActionTitle"
        :didCancel="dismissConfirmationDialog"
        :didSubmit="didConfirm"
      ></app-confirmation-dialog>
    <v-tabs v-model="activeTab">
      <v-tab>
        {{organisation.name}}
      </v-tab>
      <v-tab-item>
        <v-container>
          <v-row no-gutters>
            <v-col cols="12">
              <p class="body-2"><b>Date Created:</b> {{organisation.dateCreated | formatDate}}<br>
              <b>Date Modified:</b> {{organisation.dateModified | formatDate}}</p>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
        :headers="headers"
        :items="users"
        class="elevation-1"
        :loading="loading"
        hide-default-footer
        disable-pagination
      >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>User Accounts</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <organisation-account-new-dialog></organisation-account-new-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.firstName`]="props">
          <organisation-account-edit-dialog :account="props.item"></organisation-account-edit-dialog>
        </template>
        <template v-slot:[`item.lastSession`]="props">
          <div v-if="props.item.hasAccepted">
            <b>Last Session</b><br/>
            <span>{{ props.item.lastSession | formatDate }}</span>
          </div>
          <div v-else>
            <b>Invited</b> <a href="#" @click.prevent="resendInvite(props.item)">Resend Invite</a><br/>
            <span>{{ props.item.invitationDate | formatDate }}</span>
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <organisation-account-edit-dialog :account="item"></organisation-account-edit-dialog>
          <v-icon
            small
            class="ml-3"
            @click="confirmDeleteAccount(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          You have not added any Accounts yet.
        </template>
      </v-data-table>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="deleteDialog" max-width="300">
      <v-card>
        <v-card-title class="headline">Delete User</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          Are you sure you would like to delete this User?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="deleteItem(false)">
            Cancel
          </v-btn>
          <v-btn color="red darken-1" text @click.native="deleteItem(true)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-text-field
                v-model="editedItem.name"
                :rules="[rules.nameRequired]"
                label="Organisation Name"
              ></v-text-field>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="close">Cancel</v-btn>
            <v-btn @click="submit" class="primary" :disabled="loading" :loading="loading">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    valid: true,
    showConfirmationDialog: false,
    showEditAccountDialog: false,
    confirmationDialogTitle: '',
    confirmationDialogMessage: '',
    confirmationDialogActionTitle: '',
    activeTab: null,
    dialog: false,
    deleteDialog: false,
    itemToDelete: null,
    itemToReinvite: null,
    headers: [
      { text: 'Name', align: 'left', sortable: true, value: 'firstName' },
      { text: 'Email', align: 'left', sortable: true, value: 'email' },
      { text: 'Role', align: 'left', sortable: true, value: 'role' },
      { text: 'Status', value: 'lastSession', sortable: false }
      // { text: 'Actions', align: 'centre', value: 'action', sortable: false }
    ],
    editedIndex: -1,
    editedItem: { name: '', dateModified: '' },
    defaultItem: { name: '', dateModified: '' },
    rules: {
      nameRequired: v => !!v || 'Organisation name is required.'
    }
  }),

  created () {
    // Fetch the organisation details from the server
    const id = this.$route.params.id
    this.$store.dispatch('organisations/view', id)
    this.$store.dispatch('organisationAccounts/list', id)
    this.$store.dispatch('analytics/logScreen', { name: 'organisation-accounts-list' })
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Organisation' : 'Edit Organisation'
    },
    users () {
      return this.$store.getters['organisationAccounts/items']
    },
    loading () {
      return this.$store.getters.loading
    },
    ...mapState('organisations', {
      organisation: 'loadedItem'
    })
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  methods: {
    viewAccounts (item) {

    },
    resendInvite (item) {
      this.confirmationDialogTitle = 'Are you sure you want to resend this invite?'
      this.confirmationDialogMessage = `We’ll send another invitation to “${item.email}”`
      this.confirmationDialogActionTitle = 'Resend'
      this.itemToReinvite = item
      this.showConfirmationDialog = true
    },
    editAccount (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showEditAccountDialog = true
    },

    approve (item) {
      this.$store.dispatch('organisations/approve', this.organisation.id)
    },

    confirmDeleteAccount (item) {
      this.itemToDelete = item
      this.deleteDialog = true
    },

    deleteItem (shouldDelete) {
      if (shouldDelete) {
        this.$store.dispatch('organisationAccounts/delete',
          {
            organisationId: this.organisation.id,
            accountId: this.itemToDelete.id
          })
        // this.$ga.event('user', 'delete')
      }
      this.itemToDelete = null
      this.deleteDialog = false
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    submit () {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.$store.dispatch('organisations/update', this.editedItem)
            .then(resp => {
              this.close()
            })
        } else {
          this.$store.dispatch('organisations/create', this.editedItem)
            .then(resp => {
              this.close()
            })
        }
      }
    },
    onDismissEditAccountDialog () {
      this.showEditAccountDialog = false
    },
    dismissConfirmationDialog () {
      this.showConfirmationDialog = false
    },
    didConfirm () {
      this.showConfirmationDialog = false
      this.$store.dispatch('organisationAccounts/reinvite', {
        organisationId: this.organisation.id,
        accountId: this.itemToReinvite.id
      })
    }
  }
}
</script>
