import axios from 'axios'

const HOST = process.env.VUE_APP_ANALYTICS_HOST
const API_URL = `${HOST}/v1`

const EVENTS_URL = '/events'

function handleResponse (response, resolve, reject) {
  if (response.unauthorized) {
    reject(response.error)
  } else if (response.status === 'error') {
    reject(response.error)
  } else {
    resolve(response)
  }
}

export default {
  API_URL: `${HOST}/v1`,
  newRequest (method, url, payload) {
    const fullUrl = API_URL + url
    const headers = {
      'Content-Type': 'application/json',
      'API-Token': window.localStorage.getItem('analyticsToken')
    }

    return new Promise(function (resolve, reject) {
      axios({
        method: method,
        url: fullUrl,
        data: payload,
        headers: headers
      }).then((resp) => {
        console.log(resp.data)
        console.log(resp.status)
        console.log(resp.statusText)
        console.log(resp.headers)
        console.log(resp.config)
        handleResponse(resp.data, resolve, reject)
      }).catch((error) => {
        console.log(error)
        const errorResponse = { code: 0, title: 'Unkown API error', message: 'Unkown API error' }
        if (error.response) {
          // if (error.response.status === 401) {
          //   // Sign out unauthorized tokens
          //   store.dispatch('auth/signout')
          //   // router.push('/login')
          // } else if (error.response.data && error.response.data.status === 'error') {
          //   errorResponse = error.response.data.error
          // }
        } else {
          errorResponse.title = error.message
          errorResponse.message = error.message
        }
        reject(errorResponse)
      })
    })
  },
  logScreen: function (screenName, data) {
    const payload = {
      sessionId: window.localStorage.getItem('analyticsSessionId'),
      type: 'screen',
      name: screenName,
      data: data
    }
    return this.newRequest('post', EVENTS_URL, payload)
  },
  logEvent: function (name, data) {
    const payload = {
      sessionId: window.localStorage.getItem('analyticsSessionId'),
      type: 'action',
      name: name,
      data: data
    }
    return this.newRequest('post', EVENTS_URL, payload)
  }
}
