<template>
<v-container>
  <v-row>
    <v-col cols="12" xl="6">
      <v-carousel
        v-if="carousel"
        cycle
        height="300"
        class="rounded-lg"
        hide-delimiters
        :show-arrows="false"
      >
        <v-carousel-item
          v-for="(slide, i) in carousel.items"
          :key="i"
        >
          <v-sheet height="100%">
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-img :src="getImageSrc(slide.backgroundURL)" height="300"></v-img>
              <v-img v-if="slide.webpageURL" :src="getImageSrc(slide.foregroundURL)" class="carousel-content" style="cursor: pointer;" contain height="300" @click="openTile(slide)"></v-img>
              <v-img v-else :src="getImageSrc(slide.foregroundURL)" contain class="carousel-content" height="300"></v-img>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
  <v-row no-gutters v-if="projects">
    <v-col cols="12" class="mt-2 ml-2" v-if="pastProjects.length > 1">
      <div class="overline">Current Projects</div>
    </v-col>
    <v-col v-for="project in projects" cols="12" sm="6" xl="3" :key="project.id" class="mb-5">
      <template v-if="project.config.tile">
        <v-carousel
          cycle
          :height="project.config.tile.footerHidden ? 420 : 300"
          :show-arrows="false"
          hide-delimiters
          color="red"
          class="tile-carousel"
          interval="4000"
      >
        <v-carousel-item v-for="item in project.config.tile.carousel.items" :key="item.id" @click="explore(project)">
          <v-sheet height="100%">
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-img :aspect-ratio="4/3" :src="getImageSrc(item.backgroundURL)"></v-img>
              <div class="tile-header-title">{{item.title}}</div>
              <div class="tile-headline">{{item.headline}}</div>
              <!-- <v-img :src="getImageSrc(slide.foregroundURL)" contain class="carousel-content"></v-img> -->
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-col v-if="!project.config.tile.footerHidden" cols="12" class="tile-footer-panel mb-5" :style="getTileFooterStyle(project.config.tile)">
        <v-avatar v-if="project.config.tile.iconURL" width="92" height="92" tile class="rounded-lg float-left mr-4">
          <v-img
            :src="getImageSrc(project.config.tile.iconURL)"
            class="white--text align-end"
            height="100px"
          >
          </v-img>
        </v-avatar>
        <v-row no-gutters>
          <v-col class="pr-5">
            <div class="subtitle-1" :style="getTileFooterTitleStyle(project.config.tile)">{{project.config.tile.title}}</div>
            <div class="mb-2 subtitle-2" :style="getTileFooterSubtitleStyle(project.config.tile)">{{project.config.tile.subtitle}}</div>
            <v-btn :color="project.config.tile.actionButtonColour ? project.config.tile.actionButtonColour : 'primary'" class="float-left" dark rounded @click="explore(project)">
              Open
            </v-btn>
            <!-- <span class="selling float-left ml-2 grey--text">Now Selling<br>Under Construction</span> -->
          </v-col>
        </v-row>
      </v-col>
      </template>
      <template v-else>
        <v-avatar width="100" height="100" tile class="rounded-lg float-left mr-4">
          <v-img
            :src="getImageSrc(project.config.projectIconURL)"
            class="white--text align-end"
            height="100px"
          >
            <v-img v-if="!project.isPublished" class="dev-ribbon" :src="require('../assets/dev-ribbon.png')" height="70px"></v-img>
          </v-img>
        </v-avatar>
        <v-row no-gutters>
          <v-col class="pr-5">
            <div class="subtitle-1">{{project.name}}</div>
            <div class="mb-2 subtitle-2 grey--text text--darken-1 text-truncate">{{project.address}}</div>
            <v-btn class="primary float-left" small rounded @click="explore(project)">
              Open
            </v-btn>
            <span class="selling float-left ml-2 grey--text" v-html="project.config.status"></span>
            <v-divider class="mt-14"></v-divider>
          </v-col>
        </v-row>
      </template>
    </v-col>
  </v-row>
  <v-row no-gutters v-if="pastProjects && pastProjects.length > 0">
    <v-col cols="12" class="mt-2 ml-2">
      <div class="overline">Recent Projects</div>
    </v-col>
    <v-col v-for="pastProject in pastProjects" cols="12" sm="6" xl="3" :key="pastProject.id" class="mb-5">
      <v-avatar width="100" height="100" tile class="rounded-lg float-left mr-4">
        <v-img
          :src="getImageSrc(pastProject.config.projectIconURL)"
          class="white--text align-end"
          height="100px"
        >
        </v-img>
      </v-avatar>
      <v-row no-gutters>
        <v-col class="pr-5">
          <div class="subtitle-1">{{pastProject.name}}</div>
          <div class="mb-2 subtitle-2 grey--text text--darken-1 text-truncate">{{pastProject.address}}</div>
          <v-btn class="primary float-left" small rounded @click="explore(pastProject)">
            Open
          </v-btn>
          <span class="selling float-left ml-2 grey--text" v-html="pastProject.config.status"></span>
          <v-divider class="mt-14"></v-divider>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-if="projects.length == 0 && !loading && pastProjects.length == 0">
    <v-col
      cols="12"
    >
    <v-card outlined>
      <v-card-text class="text-center">
        No listings available. Please contact support@limpidproperty.com
      </v-card-text>
    </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col v-for="tile in tiles" cols="12" sm="6" xl="3" :key="tile.id" class="mb-5" max-width="412px">
      <v-carousel
        cycle
        :height="tile.footerHidden ? 420 : 300"
        :show-arrows="false"
        hide-delimiters
        color="red"
        class="tile-carousel"
        interval="4000"
      >
        <v-carousel-item v-for="item in tile.carousel.items" :key="item.id">
          <v-sheet height="100%">
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-img :aspect-ratio="4/3" :src="getImageSrc(item.backgroundURL)"></v-img>
              <div class="tile-header-title">{{item.title}}</div>
              <div class="tile-headline">{{item.headline}}</div>
              <!-- <v-img :src="getImageSrc(slide.foregroundURL)" contain class="carousel-content"></v-img> -->
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-col v-if="!tile.footerHidden" cols="12" class="tile-footer-panel mb-5" :style="getTileFooterStyle(tile)">
        <v-avatar v-if="tile.iconURL" width="92" height="92" tile class="rounded-lg float-left mr-4">
          <v-img
            :src="getImageSrc(tile.iconURL)"
            class="white--text align-end"
            height="100px"
          >
          </v-img>
        </v-avatar>
        <v-row no-gutters>
          <v-col class="pr-5">
            <div class="subtitle-1" :style="getTileFooterTitleStyle(tile)">{{tile.title}}</div>
            <div class="mb-2 subtitle-2" :style="getTileFooterSubtitleStyle(tile)">{{tile.subtitle}}</div>
            <v-btn v-if="tile.webpageURL" class="primary float-left" small rounded @click="openTile(tile)">
              Open
            </v-btn>
            <!-- <span class="selling float-left ml-2 grey--text">Now Selling<br>Under Construction</span> -->
          </v-col>
        </v-row>
      </v-col>
    </v-col>
  </v-row>
  <v-row dense>
    <!-- <v-col
      v-for="project in projects"
      :key="project.name"
      cols="12" sm="5"
    >
      <v-card v-if="project.type === 'banner'" outlined class="mb-1">
        <v-img
          contain
          :src="getImageSrc(project)"
          class="white--text align-end"
          height="200px"
          min-height="200px"
        >
        </v-img>
      </v-card>
      <v-card v-else outlined class="mb-1">
        <v-img
          :src="getImageSrc(project)"
          class="white--text align-end"
          height="200px"
          @click="explore(project)"
        >
        </v-img>
        <v-img v-if="!project.isPublished" class="dev-ribbon" :src="require('../assets/dev-ribbon.png')" width="80px"></v-img>
        <v-img v-if="!project.config.projectBannerHidden" :src="getBannerSrc(project)" class="white--text align-end"></v-img>
        <v-card-title v-if="!project.config.titleHidden" class="pt-2">{{project.name}}</v-card-title>
        <v-card-subtitle class="pb-0">{{project.address}}</v-card-subtitle>
        <v-card-text class="text--primary pb-0" v-html="project.description"></v-card-text>
        <v-expand-transition>
          <div v-show="project.infoExpanded">
            <v-divider></v-divider>
            <v-card-text v-html="project.description"></v-card-text>
          </div>
        </v-expand-transition>
          <v-divider class="mt-2"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="explore(project)">
            <v-icon class="mr-2" v-if="getPrimaryButtonIcon(project)">{{getPrimaryButtonIcon(project)}}</v-icon> {{getPrimaryButtonLabel(project)}}
          </v-btn>
          <v-btn v-if="project.config.timelapseEnabled" class="ml-2" outlined @click="viewTimelapse(project)">Timelapse</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-col> -->
  </v-row>
  <v-dialog
    v-model="activityDialog"
    persistent
    width="300"
  >
    <v-card
      color="gray"
      dark
      class="pt-2"
    >
      <v-card-text class="text-center">
        {{ activityDialogMessage }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <project-timelapse-dialog
    :dialog="showTimelapseDialog"
    :srcURL="timelapseSrcURL"
    :didDismiss="didDismissTimelapseDialog">
  </project-timelapse-dialog>
  <webpage-dialog
    :title="webpageTitle"
    :model="showWebpageDialog"
    :url="webpageURL"
    :fullscreen="webpageIsFullscreen"
    :didClose="didCloseWebpage">
  </webpage-dialog>
</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    projects () {
      const allProjects = this.$store.getters['projects/items']
      return allProjects.filter(function (item) { return item.type === 'firstvue' && item.isPastProject === false })
    },
    pastProjects () {
      const allProjects = this.$store.getters['projects/items']
      return allProjects.filter(function (item) { return item.type === 'firstvue' && item.isPastProject === true })
    },
    ...mapState('common', ['loading']),
    ...mapGetters('app', ['isFVC', 'assetServerURL']),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapState('app', ['carousel', 'tiles'])
  },
  data: () => ({
    item: null,
    showWebpageDialog: false,
    showTimelapseDialog: false,
    timelapseSrcURL: '',
    webpageURL: '',
    webpageTitle: '',
    webpageIsFullscreen: true,
    activityDialog: false,
    activityDialogMessage: 'Let there be light...',
    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4'
    ],
    slides: [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth'
    ]
  }),
  created () {
    this.$store.dispatch('projects/list')
    this.$store.dispatch('analytics/logScreen', { name: 'home' })
  },
  methods: {
    getPrimaryButtonLabel (project) {
      if (project.type === 'webpage') {
        if (project.config.primaryButtonLabel) {
          return project.config.primaryButtonLabel
        } else {
          return 'Visit'
        }
      } else {
        return 'Explore'
      }
    },
    getPrimaryButtonIcon (project) {
      let icon = null
      if (project.type === 'webpage') {
        if (project.config.primaryButtonIcon) {
          icon = project.config.primaryButtonIcon
        } else {
          icon = 'mdi-link'
        }
      }
      return icon
    },
    getBannerSrc (project) {
      let bannerURL = ''
      if (project.config.projectBannerURL) {
        bannerURL = project.config.projectBannerURL
      } else if (project.org.config.projectBannerURL) {
        bannerURL = project.org.config.projectBannerURL
      }
      return `${this.assetServerURL}${bannerURL}`
    },
    getImageSrc (url) {
      return `${this.assetServerURL}${url}`
    },
    explore (project) {
      if (project.type !== 'webpage') {
        if (this.isAuthenticated) {
          this.activityDialog = true
          this.$store.dispatch('analytics/logEvent', { name: 'select-project', data: { projectId: project.id, projectName: project.name } })

          const platform = window.localStorage.getItem('platform')
          if (platform === 'osx' || platform === 'win') {
            const jsonString = JSON.stringify({
              projectId: project.id
            })
            window.fvLoadSceneForProjectId(jsonString)
          } else {
            window.location.href = `uniwebview://firstvue-scene?id=${project.id}`
          }
        } else {
          if (this.isFVC) {
            this.$router.push('/fvclogin')
          }
        }
      } else {
        this.showWebpageDialog = true
        this.webpageTitle = project.name
        this.webpageURL = project.config.webpageURL
        this.webpageIsFullscreen = project.config.webpageIsFullscreen
        this.$store.dispatch('analytics/logEvent', { name: 'select-webpage', data: { projectId: project.id, projectName: project.name, url: project.config.webpageURL, fullscreen: project.config.webpageIsFullscreen } })
      }
    },
    openTile (tile) {
      if (tile.isExternalURL) {
        // This was implemented to be able to load external pages such as Matterport, where we can't show a close button.
        // Opening the url externally, will open a special webview with a native toolbar that has a "Close" button
        const url = encodeURIComponent(tile.webpageURL)
        window.location.href = 'uniwebview://load-url-external?url=' + url
      } else {
        this.showWebpageDialog = true
        this.webpageTitle = tile.title
        this.webpageURL = tile.webpageURL
        this.webpageIsFullscreen = true
      }
      this.$store.dispatch('analytics/logEvent', { name: 'select-tile', data: { tileId: tile.id, tileTile: tile.title, url: tile.webpageURL, fullscreen: true } })
    },
    viewTimelapse (project) {
      this.showTimelapseDialog = true
      this.timelapseSrcURL = project.config.timelapseURL
      this.$store.dispatch('analytics/logEvent', { name: 'select-project-timelapse', data: { projectId: project.id, projectName: project.name } })
    },
    didDismissTimelapseDialog () {
      this.showTimelapseDialog = false
    },
    didCloseWebpage () {
      this.showWebpageDialog = false
    },
    doSomething () {
      alert('test')
    },
    getTileFooterTitleStyle (item) {
      const colour = item.footerTitleColour ?? 'black'
      return `color: ${colour}`
    },
    getTileFooterSubtitleStyle (item) {
      const colour = item.footerSubtitleColour ?? '#757575'
      return `color: ${colour}`
    },
    getTileFooterStyle (item) {
      const colour = item.footerBackgroundColour ?? '#f7f7f7'
      return `background-color: ${colour}`
    }
  }
}
</script>

<style scoped>
  .v-item-group.v-bottom-navigation .v-btn.v-size--default {
    height: inherit;
  }
  .dev-ribbon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .selling {
    font-size: 8pt;
    font-weight: bold;
  }
  .carousel-content {
    position: absolute;
  }
  .tile-header-title {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 13pt;
    color: white;
  }
  .tile-headline {
    position: absolute;
    left: 10px;
    top: 40px;
    font-size: 24pt;
    color: white;
    font-weight: bold;
    line-height: 28pt;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  }
  .tile-carousel {
    border-radius: 10px 10px 0 0;
  }
  .tile-footer-panel {
    padding-bottom: 22px;
    border-radius: 0 0 10px 10px;
  }
  .dev-ribbon {
    position: absolute;
    top: 0;
    left: -4px;
  }
</style>
