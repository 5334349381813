<template>
  <v-container fluid>
    <v-layout align-center justify-center>
      <v-flex xs12 sm6 md4 lg3 xl2>
        <div class="headline mb-4 text-center">Reset Password</div>
        <v-card class="mb-4" v-if="error">
          <v-card-text>
            <p class="mb-0 red--text"><b>{{error.title}}:</b> {{error.message}}</p>
          </v-card-text>
        </v-card>
        <v-card v-if="showForm">
          <v-card-text>
            <v-form id="login-form" ref="form" v-model="valid" lazy-validation @submit.prevent="onSubmit">
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="New Password"
                prepend-icon="mdi-lock"
                type="password"
              ></v-text-field>
              <v-text-field
                v-model="passwordConfirmation"
                :error-messages='passwordMatchError()'
                label="Confirm Password"
                prepend-icon="mdi-lock"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary" form="login-form">Submit</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="resetComplete">
          <v-card-text>
            <p class="mb-0 green--text"><b>Password updated.</b> You can now login with your new password.</p>
          </v-card-text>
          <!-- <v-card-actions>
            <v-btn type="submit" color="primary" to="/login">Login</v-btn>
          </v-card-actions> -->
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    valid: false,
    isHashValid: false,
    resetComplete: false,
    password: '',
    passwordConfirmation: '',
    passwordRules: [
      v => !!v || 'Password is required'
    ],
    confirmPasswordRules: [
      v => !!v || 'Confirm Password'
    ]
  }),
  created () {
    const hash = this.$route.params.hash
    this.$store.dispatch('auth/validateResetPasswordHash', { hash: hash }).then(() => {
      this.isHashValid = true
    }).catch((error) => {
      console.log(error)
      this.isHashValid = false
    })
  },
  computed: {
    showForm () {
      return this.isHashValid && !this.resetComplete
    },
    ...mapState('common', ['error', 'loading'])
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('auth/setNewPasswordHash', { hash: this.$route.params.hash, password: this.password }).then(() => {
          this.resetComplete = true
        })
      }
    },
    passwordMatchError () {
      return (this.password === this.passwordConfirmation) ? '' : 'Passwords don\'t match'
    }
  }
}
</script>
