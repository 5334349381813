export default value => {
  if (value === 'None' || value === undefined) {
    return 'Never'
  }
  let date = new Date(value + 'Z')
  // This occurs in Safari, because the ISO date string
  // is not valid.
  // FIXME: The solution below will output AM/PM format on Safari
  // otherwise in Chrome we get 24hr format..
  if (isNaN(date.getTime())) {
    const dateString = value.replace(' ', 'T')
    date = new Date(dateString)
  }
  return date.toLocaleString()
}
