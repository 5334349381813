<template>
<v-dialog v-model="showDialog" persistent max-width="500">
  <template v-slot:activator="{ on }">
    <a href="" @click.prevent v-on="on">{{account.firstName}} {{account.lastName}}</a>
  </template>
  <v-form id="editAccountForm" ref="editAccountForm" v-model="valid" lazy-validation @submit.prevent="submit">
    <v-card>
      <v-card-title primary-title>
        <div>
          <div class="headline">Edit User</div>
        </div>
        <v-spacer></v-spacer>
        <organisation-account-edit-dialog-extra-menu :account="account" :organisationId="organisation.id"></organisation-account-edit-dialog-extra-menu>
      </v-card-title>
      <v-card-text>
        <v-row v-if="error">
          <v-col cols="12">
            <app-alert @dismissed="dismiss" :title="error.title" :text="error.message"></app-alert>
          </v-col>
        </v-row>
          <v-row>
            <v-col>
              <v-select
                  v-model="role"
                  :items="roles"
                  label="User Role"
                  item-value="value"
                  item-text="title"
                  :rules="[rules.required]"
                  ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="firstName"
                :rules="[rules.required]"
                label="First name *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
              v-model="lastName"
              :rules="[rules.required]"
              label="Last name *"
              required
            ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                :rules="[rules.required, rules.emailValid]"
                label="E-mail *"
                type="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
              v-model="assignedProjects"
              :items="projects"
              item-value="id"
              item-text="name"
              attach
              small-chips
              label="Projects"
              multiple
            ></v-select>
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dismiss">Cancel</v-btn>
        <v-btn type="submit" class="primary" :disabled="loading" :loading="loading" form="editAccountForm" @click="submit">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['account'],
  data: () => ({
    valid: true,
    showDialog: false,
    editPassword: false,
    userId: null,
    firstName: '',
    lastName: '',
    email: '',
    assignedProjects: [],
    rules: {
      required: v => !!v || 'Required.',
      agree: v => !!v || 'You must agree to continue!',
      min: v => (v !== undefined && v.length >= 8) || 'Min 8 characters',
      emailValid: v => /.+@.+/.test(v) || 'E-mail must be valid',
      match: v => v === this.password || 'Passwords don\'t match'
    },
    role: ''
  }),
  watch: {
    account (value) {
      this.userId = value.id
      this.firstName = value.firstName
      this.lastName = value.lastName
      this.email = value.email
      this.role = value.role
      this.assignedProjects = value.assignedProjects
    },
    showDialog (value) {
      if (value) {
        this.userId = this.account.id
        this.firstName = this.account.firstName
        this.lastName = this.account.lastName
        this.email = this.account.email
        this.role = this.account.role
        this.assignedProjects = this.account.assignedProjects
        this.$store.dispatch('organisationProjects/list', this.organisation.id)
      }
    }
  },
  mounted () {
    this.$store.dispatch('common/clearError')
  },
  computed: {
    roles () {
      const roles = []
      if (this.user.role === 'superuser') {
        roles.push({ value: 'developer', title: 'Developer' })
        roles.push({ value: 'system-admin', title: 'System Admin' })
        roles.push({ value: 'system-manager', title: 'System Manager - Manages Agencies, Tours & User Accounts' })
      }
      // if (this.$store.getters['auth/isSystemAdmin']) {
      // }
      // if (this.$store.getters['auth/isSystemManager']) {
      //   }
      // roles.push({ value: 'account-holder', title: 'Account Holder - Owns Agency account' })
      roles.push({ value: 'account-admin', title: 'Account Admin - Can invite other users' })
      roles.push({ value: 'account-default', title: 'Default User' })
      return roles
    },
    ...mapState('common', ['error', 'loading']),
    ...mapState('auth', ['user']),
    ...mapState('organisations', {
      organisation: 'loadedItem'
    }),
    ...mapState('organisationProjects', ['projects'])
  },
  methods: {
    submit () {
      if (this.$refs.editAccountForm.validate()) {
        const that = this
        this.$store.dispatch('organisationAccounts/update',
          {
            organisationId: this.organisation.id,
            accountId: this.account.id,
            payload: {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              role: this.role,
              assignedProjects: this.assignedProjects
            }
          })
          .then(resp => {
            that.dismiss()
          })
      }
    },
    showResetPasswordDialog () {
      this.editPassword = true
    },
    dismissEditPassword () {
      this.editPassword = false
    },
    dismiss () {
      this.$refs.editAccountForm.reset()
      this.$store.dispatch('common/clearError')
      this.showDialog = false
    }
  }
}
</script>
